import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import AppTemplateContainer from './containers/templates/app';
import HomeTemplate from './components/templates/home';
import CheckSetupPage from './components/pages/check-setup';
import StoryPageContainer from './containers/pages/story';
import CalibrationPageContainer from './containers/pages/calibration';
import ConfirmUserPageContainer from './containers/pages/confirm-user';
import StorySelectionPageContainer from './containers/pages/story-selection';
import AppStateProviders from './containers/shared/context/app-state';
import { SnackbarProvider } from 'notistack';
import { useAppContext } from './containers/shared/context/app-state/app-context';
import { AuthCallback, AuthProvider } from './auth';
import { GraphQLProvider } from './graphql';
import { RecorderProvider } from './services/recording/bindings/react';
import GlobalTemplate from './containers/templates/global';

const ConfirmUserRoute: React.FC = () => {
  const history = useHistory();

  return (
    <ConfirmUserPageContainer onNext={() => history.push(`/check-setup${window.location.search}`)} />
  );
};

const CheckSetupRoute: React.FC = () => {
  const history = useHistory();

  return (
    <CheckSetupPage onBack={() => history.push(`/confirm-user${window.location.search}`)} onNext={() => {
      history.push(`/calibration${window.location.search}`); // was /story
    }} />
  );
};

const CalibrationRoute: React.FC = () => {
  const history = useHistory();

  return (
    <CalibrationPageContainer onBack={() => history.push(`/check-setup${window.location.search}`)}
                              onNext={() => history.push(`/story${window.location.search}`)} />
  );
};

const StoryRoute: React.FC = () => {
  const history = useHistory();

  return (
    <StoryPageContainer onComplete={() => {
      history.push(`/story-select${window.location.search}`);
    }} />
  );
};

const StorySelectionRoute: React.FC = () => {
  const history = useHistory();
  const { user } = useAppContext();

  return (
    <StorySelectionPageContainer onSelectedStory={selectedStoryId => {
      user?.assignment?.setContentId(selectedStoryId);
      history.push(`/story${window.location.search}`);
    }} />
  );
};

const Routes: React.FC = () => (
  <Router>
    <QueryParamProvider ReactRouterRoute={Route}>
      <AuthProvider>
        <GraphQLProvider>
          <RecorderProvider>

            <AppStateProviders>
              <SnackbarProvider maxSnack={3}>
                <GlobalTemplate>
                  <AppTemplateContainer>
                    <HomeTemplate>
                      <Switch>
                        <Route exact path={'/'} component={ConfirmUserRoute} />
                        <Route exact path={'/cb'}>
                          <AuthCallback />
                        </Route>
                        <Route exact path={'/confirm-user'} component={ConfirmUserRoute} />
                        <Route exact path={'/check-setup'} component={CheckSetupRoute} />
                        <Route exact path={'/calibration'} component={CalibrationRoute} />
                        <Route exact path={'/tutorial'} component={StoryRoute} />
                        <Route exact path={'/story/:id?'} component={StoryRoute} />
                        <Route exact path={'/story-select'} component={StorySelectionRoute} />
                        <Redirect to='/' />
                      </Switch>
                    </HomeTemplate>
                  </AppTemplateContainer>
                </GlobalTemplate>
              </SnackbarProvider>
            </AppStateProviders>
          </RecorderProvider>
        </GraphQLProvider>
      </AuthProvider>
    </QueryParamProvider>
  </Router>
);

const App: React.FC = () => {
  return (
    <Routes />
  );
};

export default App;
