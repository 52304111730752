import React from 'react';
import SetupTile from "../../molecules/setup-tile";
import BG from "./bg.png";
import KeyboardPanelProxy from "../../molecules/keyboard-panel/context";

export interface IIntroTileProps {
  onBack(): void;
  onNext(): void;
}

const IntroTile: React.FC<IIntroTileProps> = props => {
  return (
    <>
      <SetupTile imageUrl={BG}>
        <div className={"flex flex-column vocalid-primary-text"}>
          <h2 className={"vocalid-h2 white tl"}>
            Let’s make sure your microphone is accessible.
          </h2>
          <div>
            <p className={"vocalid-list-title mb4"}>
              On the next screen, you may be asked to allow VocaliD access to your microphone. Be sure to click "Allow", so we can receive your audio!
            </p>
            <p className={"vocalid-list-title mb4"}>
              When you're ready, click <span>NEXT</span>.
            </p>
          </div>
        </div>
      </SetupTile>
      <KeyboardPanelProxy currentRecording={null} onBack={props.onBack} onNext={props.onNext} />
    </>
  );
};

export default IntroTile;
