import {EventEmitter} from "events";

export interface InitializedRecordingPlaybackHelperSetup {
  audioContext: AudioContext;
  sampleRate: number;
  bufferSize: number;
  nodes: InitializedRecordingPlaybackHelperSetupNodes;
}

export interface InitializedRecordingPlaybackHelperSetupNodes {
  bufferSource: AudioBufferSourceNode;
  gain: GainNode;
  analyzer: AnalyserNode;
  destination: AudioDestinationNode;
}

export enum ERecordingPlaybackHelperEvent {
  PLAYBACK_STARTED = "playback-started",
  PLAYBACK_STOPPED = "playback-stopped",
  PROGRESS = "progress"
}

class RecordingPlaybackHelper extends EventEmitter {
  setup: InitializedRecordingPlaybackHelperSetup | null = null;

  private initialize() {
    const AudioContext: typeof window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;

    const requestedSampleRate = 48000;

    const audioContext = new AudioContext({
      latencyHint: 'playback',
      sampleRate: requestedSampleRate
    });

    // parameters
    const sampleRate = audioContext.sampleRate;
    const bufferSize = 4096;

    const bufferSourceNode = audioContext.createBufferSource();
    const gain = audioContext.createGain();
    bufferSourceNode.connect(gain);

    //
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = bufferSize;
    gain.connect(analyzer);

    const destination = audioContext.destination
    gain.connect(destination);

    this.setup = {
      audioContext,
      sampleRate,
      bufferSize,
      nodes: {
        bufferSource: bufferSourceNode,
        gain,
        analyzer,
        destination
      }
    }
  }

  startPlayback(audioData: Blob) {
    if (!this.setup) {
      this.initialize();
    }

    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      const arrayBuffer = fileReader.result as ArrayBuffer;

      this.setup!.audioContext.decodeAudioData(arrayBuffer, (buffer) => {
        this.emit(ERecordingPlaybackHelperEvent.PLAYBACK_STARTED);
        this.setup!.nodes.bufferSource.buffer = buffer;

        console.log(`Playback rate: ${this.setup!.nodes.bufferSource.playbackRate.value}`);

        this.setup!.nodes.bufferSource.start();
        this.setup!.nodes.bufferSource.onended = this.onEnded.bind(this);

        const startTime = this.setup!.audioContext.currentTime;

        const playbackInterval = setInterval(() => {
          if (!this.setup) {
            clearInterval(playbackInterval);
            return;
          }

          const completionPercentage = (this.setup!.audioContext.currentTime - startTime)/this.setup!.nodes.bufferSource.buffer!.duration/this.setup!.nodes.bufferSource.playbackRate.value;
          this.emit(ERecordingPlaybackHelperEvent.PROGRESS, completionPercentage);
        }, 10)
      });
    }

    fileReader.readAsArrayBuffer(audioData);
  }

  onEnded() {
    this.setup?.nodes.bufferSource.stop();
    this.emit(ERecordingPlaybackHelperEvent.PLAYBACK_STOPPED);
    this.disconnect()
  }

  stopPlayback() {
    if (this.setup) {
      this.setup.nodes.bufferSource.stop();
      this.emit(ERecordingPlaybackHelperEvent.PLAYBACK_STOPPED);
      this.disconnect()
    }
  }

  disconnect() {
    if (!this.setup) { return; }

    this.setup.nodes.bufferSource.disconnect()
    this.setup.nodes.gain.disconnect()
    this.setup.nodes.analyzer.disconnect()
    this.setup.nodes.destination.disconnect()

    this.setup = null;
  }
}

export default RecordingPlaybackHelper;
