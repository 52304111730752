import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecorderContext } from '../../../../services/recording/bindings/react';
import UAParser from 'ua-parser-js';
import { useAppContext } from './app-context';
import { useCreateRecordingSessionMutation } from '../../../../graphql/generated';

interface ICurrentSessionContext {
  session: {
    id: string;
    calibration: {
      backgroundNoise: CalibrationItem;
      talentVolume: CalibrationItem;
    }
  } | null;
}

export interface CalibrationItem {
  id: string;
  uploadUrl: string;
}

const CurrentSessionContext = React.createContext<ICurrentSessionContext>({
  session: null
})

export const CurrentSessionContextProvider: React.FC = props => {
  const history = useHistory();
  const assignment = useAppContext().user?.assignment;
  const assignmentId = assignment?.id;
  const [createSessionMutation] = useCreateRecordingSessionMutation();
  const [currentSession, setCurrentSession] = React.useState<ICurrentSessionContext['session'] | null>(null);

  const recorderContext = useRecorderContext()
  const recordingDeviceName = recorderContext.inputDevices.find(d => d.deviceId === recorderContext.currentInputDeviceId)?.label ?? null;

  React.useEffect(() => {
    if (assignmentId) {
      const userAgentParser = new UAParser();
      const userAgent = userAgentParser.getResult();

      createSessionMutation({ variables: {
        input: {
          assignment: assignmentId,
          recordingDeviceName,
          browser: userAgent.browser.name ? `${userAgent.browser.name}${userAgent.browser.version ? " v" + userAgent.browser.version : ""}` : null,
          operatingSystem: userAgent.os.name ? `${userAgent.os.name}${userAgent.os.version ? " v" + userAgent.os.version : ""}` : null,
          userAgent: userAgent.ua
        }
      }})
        .then(sessionData => {
          const createdSession = sessionData.data?.createRecordingSession;
          console.log(`created recording session: ${createdSession?.id}`)

          if (createdSession) {
            setCurrentSession({
              id: createdSession.id,
              calibration: {
                backgroundNoise: createdSession.backgroundNoiseCalibrationItem!,
                talentVolume: createdSession.talentVolumeCalibrationItem!
              }
            });

            // redirect
            if (!["/", "/confirm-user"].includes(history.location.pathname)) {
              console.log("New session created, redirecting to /confirm-user...");
              history.push(`/confirm-user${window.location.search}`);
            }
          }
        });
    }
  }, [assignmentId, recordingDeviceName]);

  return <CurrentSessionContext.Provider value={{
    session: currentSession
  }}>
    {props.children}
  </CurrentSessionContext.Provider>
}

export const useCurrentSession = () => React.useContext(CurrentSessionContext).session;
