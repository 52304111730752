import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import useAudioVisualizer, { RecordingVisualizerInput } from '../../../services/recording/bindings/react/visualizer';
import useRecorder from '../../../services/recording/bindings/react';
import Select from '../../molecules/select';
import { useHistory } from 'react-router-dom';
import Tile from '../tile';
import { OutlineButton, PrimaryButton } from '../button';
import Tooltip from '../tooltip';
import Icon, { EIconName } from '../icon';

export const AudioVisualizerView: React.FC<RecordingVisualizerInput & {
  progress?: number;
  className?: string;
}> = ({ progress, className, ...props }) => {
  const [canvasRef, vizProps] = useAudioVisualizer(props);
  const { inputDevices, currentInputDevice, setCurrentInputDeviceId } = useRecorder();
  const history = useHistory();
  const [showRestartWarningForDeviceId, setShowRestartWarningForDeviceId] = React.useState<string | null>(null);

  return (
    <div className={classNames("absolute top-0 left-0 right-0 bottom-0", className)}>
      <div className={"absolute top-0 left-0 right-0 bottom-0 z-1"}>
        {progress && <div className={css({
          width: '100%',
          height: '100%'
        })}>
          <div className={classNames("h-100", css({
            width: `${Math.ceil(progress*100)}%`,
            transition: 'width 10ms',
            backgroundColor: "#416D7D"
          }))} />
        </div>}
      </div>
      <div className={"relative w-100 h-100 flex-shrink-0 z-2"}>
        <canvas ref={canvasRef} className={"w-100 h-100"} />
      </div>
      <div className={"absolute top-0 left-0 right-0 bottom-0 flex flex-column justify-start items-center vocalid-primary-text"}>
        <div className={"z-999 inline-flex items-center"}>
          <div className={"pa2 w5"}>
            {inputDevices.length !== 0 && currentInputDevice && <Select value={currentInputDevice?.deviceId ?? ""} onChange={newValue => {
              if (newValue !== currentInputDevice?.deviceId ?? "") {
                //setCurrentInputDeviceId(newValue as string);
                setShowRestartWarningForDeviceId(newValue as string);
                //history.push("/confirm-user");
              }
            }} className={classNames("w-100", css({
              backgroundColor: "#264757"
            }))}>
              {inputDevices.map(device => ({
                label: device.label,
                value: device.deviceId
              }))}
            </Select> }
          </div>
          { currentInputDevice?.label.includes("Built-in") && <Tooltip tooltipContent={<>You may be using the built-in microphone of your computer, which is not recommended.<br/><br/>Please switch to an external microphone if available.</>}><Icon name={EIconName.Warning} /></Tooltip> }
        </div>
        {showRestartWarningForDeviceId && <div className={classNames("fixed top-0 left-0 right-0 bottom-0 flex justify-center items-start vocalid-secondary-text", css({
          paddingTop: '15%',
          backgroundColor: 'rgba(0,0,0,0.5)'
        }))}>
          <Tile heading={<div className={'vocalid-h2 mr2'}>Are you sure you want to change <br/>your input source?</div>}>
            Changing your input source to <strong className={"vocalid-primary-text"}>{(inputDevices ?? []).find(d => d.deviceId === showRestartWarningForDeviceId)?.label}</strong> will restart your session.<br/>
            <div className={"flex justify-end mt4"}>
              <OutlineButton
                size={'medium'}
                onClick={() => {
                  setShowRestartWarningForDeviceId(null);
                }}
                className={'mr4'}
              >
                CANCEL
              </OutlineButton>
              <PrimaryButton onClick={() => {
                setCurrentInputDeviceId(showRestartWarningForDeviceId!);
                setShowRestartWarningForDeviceId(null);
                history.push(`/confirm-user${window.location.search}`);
              }}>
                CONFIRM
              </PrimaryButton>
            </div>
          </Tile>
        </div>}
      </div>
      <div className={"absolute top-0 left-0 right-0 bottom-0 flex flex-column justify-end items-end vocalid-primary-text"}>
        {vizProps.active && <div className={"pa2"}>
          <div>{Math.ceil(vizProps.peakInstantaneousPowerDecibels)} dB</div>
        </div> }
      </div>
    </div>
  )
}
