import {EventEmitter} from "events";

interface IAudioVisualizerSetup {
  analyzerNode: AnalyserNode;
}

interface IAudioVisualizerState {
  setup: IAudioVisualizerSetup;
  working: {
    canvasContext: CanvasRenderingContext2D;
    width: number;
    height: number;
    tmp: {
      then: number;
    };
    dataArray: Float32Array;
  } | null;
}

export enum EAudioVisualizerEvent {
  VISUALIZATION_STARTED = "visualization-started",
  VISUALIZATION_STOPPED = "visualization-stopped",
  DB_PROPS_UPDATED = "dB-props-updated"
}

export interface IVizProps {
  peakInstantaneousPowerDecibels: number;
}

export default class AudioVisualizer extends EventEmitter {
  private state: IAudioVisualizerState | null = null;
  private canvasEl: HTMLCanvasElement | null = null;

  private fps = 12;
  dangerColor = "#FF0000"
  lineColor = "white"
  clippingBoundsLineColor = "white"

  setCanvas(canvasEl: HTMLCanvasElement) {
    this.canvasEl = canvasEl;
  }

  isInitialized() {
    return Boolean(this.state);
  }

  private initialize(setup: IAudioVisualizerSetup) {
    this.state = {
      setup,
      working: null
    };
  }

  startVisualization(setup: IAudioVisualizerSetup) {
    this.initialize(setup);
    if (!this.canvasEl || !this.state) { return; }

    const canvasContext = this.canvasEl.getContext("2d")!
    const dpr = window.devicePixelRatio || 1;

    const rect = this.canvasEl.getBoundingClientRect();

    let resWidth = rect.width * dpr;
    let resHeight = rect.height * dpr;

    this.canvasEl.width = resWidth;
    this.canvasEl.height = resHeight;

    canvasContext.scale(dpr, dpr);

    let dataArray = new Float32Array(setup.analyzerNode.fftSize);

    canvasContext!.clearRect(0, 0, resWidth, resHeight);

    this.state.working = {
      canvasContext,
      width: rect.width,
      height: rect.height,
      tmp: {
        then: Date.now()
      },
      dataArray
    }

    this.draw()
    this.emit(EAudioVisualizerEvent.VISUALIZATION_STARTED)
  }

  private draw() {
    if (!this.state || !this.state.working) { return; } // FIXME

    // if (!this.state.setup) {
    //   this.canvasContext!.clearRect(0, 0, width, height);
    //   return;
    // }

    let fpsInterval = 1000 / this.fps;

    requestAnimationFrame(this.draw.bind(this));

    let now = Date.now();
    let elapsed = now - this.state.working.tmp.then;

    // if enough time has elapsed, draw the next frame
    if (elapsed < fpsInterval) {
      return;
    }

    // Get ready for next frame by setting then=now, but also adjust for your
    // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
    this.state.working.tmp.then = now - (elapsed % fpsInterval);

    // Oscilloscope code from:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API

    this.state.setup.analyzerNode.getFloatTimeDomainData(this.state.working.dataArray)

    const ctx = this.state.working.canvasContext!

    ctx.clearRect(0,0,this.state.working.width, this.state.working.height);

    ctx.lineWidth = 1;
    ctx.strokeStyle = this.lineColor;

    ctx.beginPath();

    let bufferStep = 1;
    let screenStep = this.state.working.width * (bufferStep / this.state.setup.analyzerNode.fftSize);
    let x = 0;

    for(let i = 0; i < this.state.setup.analyzerNode.fftSize; i += bufferStep) {
      let v = this.state.working.dataArray[i]! * this.state.working.height;
      let y = v + this.state.working.height/2;

      if(i === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }

      x += screenStep;
    }

    ctx.lineTo(this.state.working.width, this.state.working.height/2);
    ctx.stroke();
    ctx.closePath();

    // clipping bounds
    const peakInstantaneousPower = this.state.working.dataArray.reduce((acc, curr) => Math.max(curr, acc), 0)

    const maxPower = 0.75;
    const maxV = maxPower * this.state.working.height/2;

    ctx.strokeStyle = this.clippingBoundsLineColor;
    ctx.beginPath();

    // bottom line
    ctx.moveTo(0, maxV + this.state.working.height/2);
    ctx.lineTo(this.state.working.width, maxV + this.state.working.height/2);

    // top line
    ctx.moveTo(0, this.state.working.height/2 - maxV);
    ctx.lineTo(this.state.working.width, this.state.working.height/2 - maxV);

    ctx.stroke();
    ctx.closePath();

    if (peakInstantaneousPower >= maxPower) {
      // bottom rect
      ctx.fillStyle = this.dangerColor;
      ctx.fillRect(0, maxV + this.state.working.height/2, this.state.working.width, this.state.working.height);

      // top rect
      ctx.fillStyle = this.dangerColor;
      ctx.fillRect(0, 0, this.state.working.width, this.state.working.height/2 - maxV);
    }

    // Compute peak instantaneous power over the interval.
    const peakInstantaneousPowerDecibels = 10 * Math.log10(peakInstantaneousPower ** 2);

    const vizProps: IVizProps = {
      peakInstantaneousPowerDecibels
    }

    this.emit(EAudioVisualizerEvent.DB_PROPS_UPDATED, vizProps);
  }

  stopVisualization() {
    if (!this.state) { return; }

    if (this.state.working) {
      this.state.working.canvasContext!.clearRect(0, 0, this.state.working.width, this.state.working.height);
    }

    this.state = null;
    this.emit(EAudioVisualizerEvent.VISUALIZATION_STOPPED)
  }
}
