import React from 'react';

interface IThemeContext {
  colors: {
    green: string;
    orange: string;
    red: string;
    blue: string;
    darkBlue: string;
    header: string;
    headerBorder: string;
    page: string;
    tile: string;
    tileAlt: string;
    tileStroke: string;
    primaryText: string;
    secondaryText: string;
    tertiaryText: string;
    darkText: string;
    link: string;
    iconActive: string;
    iconInactive: string;
    clipActive: string;
    clipInactive: string;
    tag: string;
    tagText: string;
    sliderGroove: string;
    sliderHandle: string;
    sliderHandleStroke: string;
    primaryButton: string;
    primaryButtonText: string;
    popover: string;
    banner: string;
  };
  typography: {
    body: {
      fontFamily: string;
    };
  };
}

const defaultTheme: IThemeContext = {
  colors: {
    green: '#3DD5A9',
    orange: '#EC9520',
    red: '#F63D4F',
    blue: '#1D99CB',
    darkBlue: '#0F416B',
    header: '#1C2E36',
    headerBorder: '#13181A',
    page: '#243C46',
    tile: '#2B4D5A',
    tileAlt: '#213B45',
    tileStroke: '#203942',
    primaryText: '#E5E9EB',
    secondaryText: '#A2BFCB',
    tertiaryText: '#708E9B',
    darkText: '#3E4649',
    link: '#62E3FF',
    iconActive: '#3CC4FA',
    iconInactive: '#708E9B',
    clipActive: '#0D1315',
    clipInactive: '#192427',
    tag: '#586B74',
    tagText: '#E5E9EB',
    sliderGroove: '#131414',
    sliderHandle: '#213B45',
    sliderHandleStroke: '#526D7A',
    primaryButton: '#13C08E',
    primaryButtonText: '#0B3A2D',
    popover: '#BCCCD2',
    banner: '#FBDB6A'
  },
  typography: {
    body: {
      fontFamily: "'Roboto', sans-serif"
    }
  }
};

const ThemeContext = React.createContext<IThemeContext>(defaultTheme);

export const useTheme = () => React.useContext(ThemeContext);

export interface IThemeProviderProps {}

const toKebabCase = (string: string) =>
  string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

const ThemeProvider: React.FC<IThemeProviderProps> = (props) => {
  const theme = defaultTheme; // for hot-swapping, replace this with state handler

  const themeColorNames = Object.keys(theme.colors);
  const specificThemeCssNameOverrides: { [key: string]: string } = {};
  const transformedCssNames = themeColorNames.reduce<{ [key: string]: string }>(
    (acc, curr) => ({
      ...acc,
      [curr]: specificThemeCssNameOverrides[curr] ?? toKebabCase(curr)
    }),
    {}
  );

  const colorCSS = themeColorNames
    .map(
      (colorName) => `
.vocalid-${transformedCssNames[colorName]} { color: ${(theme.colors as any)[colorName]}; }
.bg-vocalid-${transformedCssNames[colorName]} { background-color: ${
        (theme.colors as any)[colorName]
      }; }
.b--vocalid-${transformedCssNames[colorName]} { border-color: ${(theme.colors as any)[colorName]}; }
`
    )
    .join('\n');

  return (
    <ThemeContext.Provider value={theme}>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@400;500;700&family=Roboto+Mono&display=swap"
        rel="stylesheet"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
${colorCSS}

.vocalid-h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.vocalid-h2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'cpsp' on;
}

.vocalid-h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
}

.vocalid-body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.vocalid-nav {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'cpsp' on;
}

.vocalid-tile-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'cpsp' on;
}

.vocalid-list-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
}

.vocalid-stat-number {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 51px;
  line-height: 60px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.vocalid-dictionary-header {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
}

/* width */
::-webkit-scrollbar {
  cursor: pointer;
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: ${theme.colors.clipInactive};
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #456F80;
}

::-webkit-scrollbar-thumb:active {
  background-color: #608FA3;
}
      `
        }}
      />
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
