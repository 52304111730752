import React, { EventHandler, MouseEvent } from 'react';
import {
  FaCircle,
  FaUndo, FiChevronDown, IoIosWarning,
  IoMdArrowBack,
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdArrowForward, IoMdClose,
  IoMdPlay,
  IoMdSquare, IoMdThumbsUp, MdReport
} from 'react-icons/all';
import { IconType as ReactIconsType } from 'react-icons';
import { useTheme } from '../../../theme';
import classNames from 'classnames';
import { css } from 'emotion';
import { ReactComponent as CheckmarkSVG } from './svg/checkmark.svg';
import { ReactComponent as RecordIndicatorInactiveSVG } from './svg/record-indicator-inactive.svg';
import { ReactComponent as RecordIndicatorActiveSVG } from './svg/record-indicator-active.svg';
import { ReactComponent as MdReportSolidSVG } from './svg/md_report_solid.svg';
import { ReactComponent as MdReportOutlineSVG } from './svg/md_report_outline.svg';

export enum EIconName {
  Recording,
  Play,
  ArrowLeft,
  ArrowRight,
  FilledArrowDown,
  FilledArrowUp,
  DropdownArrowDown,
  Checkmark,
  RecordIndicatorInactive,
  RecordIndicatorActive,
  Undo,
  Approve,
  ReportInactive,
  ReportActive,
  Stop,
  X,
  Warning
}

export interface IIconProps {
  name: EIconName;
  color?: string;
  hoverColor?: string;
  activeColor?: string;

  active?: boolean;
  size?: number;

  children?: undefined;

  onClick?: EventHandler<MouseEvent<SVGElement>>;
  onMouseOver?: EventHandler<MouseEvent<SVGElement>>;

  className?: string;
  style?: React.CSSProperties;
}

const Icon: React.FC<IIconProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const iconColor = props.color
    ? (themeColors as any)[props.color] ?? props.color
    : themeColors.iconInactive;
  const activeColor = props.activeColor
    ? (themeColors as any)[props.activeColor] ?? props.activeColor
    : themeColors.iconActive;
  const hoverColor = props.hoverColor
    ? (themeColors as any)[props.hoverColor] ?? props.hoverColor
    : props.active || props.onClick
    ? activeColor
    : iconColor;

  let iconDef:
    | ['react-icons', ReactIconsType, boolean?]
    | ['svg', React.ComponentType<React.SVGProps<SVGSVGElement>>];

  switch (props.name) {
    case EIconName.Recording:
      iconDef = ['react-icons', FaCircle];
      break;
    case EIconName.Play:
      iconDef = ['react-icons', IoMdPlay];
      break;
    case EIconName.ArrowLeft:
      iconDef = ['react-icons', IoMdArrowBack];
      break;
    case EIconName.ArrowRight:
      iconDef = ['react-icons', IoMdArrowForward];
      break;
    case EIconName.FilledArrowDown:
      iconDef = ['react-icons', IoMdArrowDropdown];
      break;
    case EIconName.FilledArrowUp:
      iconDef = ['react-icons', IoMdArrowDropup];
      break;
    case EIconName.DropdownArrowDown:
      iconDef = ['react-icons', FiChevronDown, true];
      break;
    case EIconName.Checkmark:
      iconDef = ['svg', CheckmarkSVG];
      break;
    case EIconName.RecordIndicatorInactive:
      iconDef = ['svg', RecordIndicatorInactiveSVG];
      break;
    case EIconName.RecordIndicatorActive:
      iconDef = ['svg', RecordIndicatorActiveSVG];
      break;
    case EIconName.Undo:
      iconDef = ['react-icons', FaUndo]
      break;
    case EIconName.Approve:
      iconDef = ['react-icons', IoMdThumbsUp];
      break;
    case EIconName.Stop:
      iconDef = ['react-icons', IoMdSquare];
      break;
    case EIconName.X:
      iconDef = ['react-icons', IoMdClose];
      break;
    case EIconName.Warning:
      iconDef = ['react-icons', IoIosWarning];
      break;
    case EIconName.ReportInactive:
      iconDef = ['svg', MdReportOutlineSVG];
      break;
    case EIconName.ReportActive:
      iconDef = ['svg', MdReportSolidSVG];
      break;
  }

  const [iconType, IconComponent, noFill] = iconDef;

  const color = !props.active ? iconColor : activeColor;
  const size = props.size ?? 25;

  if (iconType === 'react-icons') {
    return (
      <IconComponent
        onClick={props.onClick}
        color={color}
        size={size}
        className={classNames(
          css({
            boxSizing: 'content-box'
          }),
          css({
            cursor: props.onClick && 'pointer',
            '&:hover': {
              color: hoverColor,
              fill: !noFill && hoverColor
            }
          }),
          props.className
        )}
        style={props.style}
      />
    );
  } else if (iconType === 'svg') {
    const computedSize = size || '1em';

    return (
      <IconComponent
        onClick={props.onClick}
        height={computedSize}
        width={computedSize}
        className={classNames(
          css({
            boxSizing: 'content-box'
          }),
          css({
            color
          }),
          css({
            cursor: props.onClick && 'pointer',
            '&:hover': {
              color: hoverColor
            }
          }),
          props.className
        )}
        style={props.style}
      />
    );
  } else {
    return null;
  }
};

export default Icon;
