import React from "react";
import {IBaseKeyboardPanelProps} from "./index";

export interface IKeyboardPanelProxyContext {
  data: IBaseKeyboardPanelProps;
  set(data: IBaseKeyboardPanelProps): void
}

const initialState: IBaseKeyboardPanelProps = {
  currentRecording: null
}

export const KeyboardPanelProxyContext = React.createContext<IKeyboardPanelProxyContext>({
  data: initialState,
  set: () => {}
})

export const KeyboardPanelProxyContextProvider: React.FC = (props) => {
  const [data, setData] = React.useState<IBaseKeyboardPanelProps>(initialState)

  return (
    <KeyboardPanelProxyContext.Provider value={{
      data,
      set: setData
    }}>
      {props.children}
    </KeyboardPanelProxyContext.Provider>
  )
};

const KeyboardPanelProxy: React.FC<IBaseKeyboardPanelProps> = props => {
  const { set } = React.useContext(KeyboardPanelProxyContext);

  React.useEffect(() => {
    set(props)
  }, [props, set])

  return null;
};

export default KeyboardPanelProxy;
