import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Long type */
  Long: GQLLong;
};

export type AcceptAccountTOSInput = {
  account: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type AcceptTalentUsageInput = {
  account: Scalars['String'];
  talent: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type AccountMultiWhereInput = {
  name?: InputMaybe<AccountNameWhereInput>;
};

export type AccountNameWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type AccountProjectsInput = {
  pagination: PaginationInput;
};

export type AccountRenderingAttemptsInput = {
  pagination: PaginationInput;
};

export type AccountSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AccountSortingInput = {
  name?: InputMaybe<Scalars['Boolean']>;
};

export type AccountTalentEntitlementsInput = {
  pagination: PaginationInput;
  where?: InputMaybe<AccountTalentEntitlementsWhereInput>;
};

export type AccountTalentEntitlementsWhereInput = {
  hasAssignments?: InputMaybe<Scalars['Boolean']>;
};

export type AccountVoiceEntitlementsInput = {
  pagination: PaginationInput;
  sorting?: InputMaybe<VoiceSortingInput>;
  where?: InputMaybe<VoiceMultiWhereInput>;
};

export type AccountsQueryInput = {
  pagination: PaginationInput;
  sorting?: InputMaybe<AccountSortingInput>;
  where?: InputMaybe<AccountMultiWhereInput>;
};

export type AdMixContentInput = {
  maxDurationSeconds?: InputMaybe<Scalars['Int']>;
  sections: Array<AdMixContentSectionInput>;
  soundTemplateId?: InputMaybe<Scalars['String']>;
};

export type AdMixContentSectionInput = {
  items: Array<PlaylistItemInput>;
  key: Scalars['String'];
};

export enum AdMixSoundTemplateTempo {
  Down = 'Down',
  Mid = 'Mid',
  Up = 'Up'
}

export type AllTalentsQueryInput = {
  where?: InputMaybe<TalentMultiWhereInput>;
};

export type AllVoicesQueryInput = {
  sorting?: InputMaybe<VoiceSortingInput>;
  where?: InputMaybe<VoiceMultiWhereInput>;
};

export type AnalyticsInput = {
  account: Scalars['String'];
  bucketSize: BucketSize;
  dateEnd: Scalars['Long'];
  dateStart: Scalars['Long'];
};

export type AssignmentRecordingsFilterInput = {
  approvalFlag?: InputMaybe<RecordingApprovalFlag>;
};

export type AssignmentRecordingsWhereInput = {
  filter?: InputMaybe<AssignmentRecordingsFilterInput>;
  pagination: PaginationInput;
};

export type AssignmentSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AssignmentsQueryInput = {
  pagination: PaginationInput;
};

export type BatchUpdateRecordingSessionContentLineItemApprovalFlagInput = {
  clientApprovalFlag: RecordingApprovalFlag;
  recordingIds: Array<Scalars['String']>;
};

export enum BucketSize {
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Month = 'Month',
  Year = 'Year'
}

export type ChangeMixFormatMutationInput = {
  format: MixFormat;
  id: Scalars['String'];
};

export type ChangePricingPlanForAccountInput = {
  account: Scalars['String'];
  pricingPlan: Scalars['String'];
};

export type ClipsQueryInput = {
  account: Scalars['String'];
  pagination: PaginationInput;
};

export type ContentSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ContentTagsQueryInput = {
  pagination: PaginationInput;
};

export type ContentsQueryInput = {
  inList?: InputMaybe<Array<Scalars['String']>>;
  pagination: PaginationInput;
};

export type CreateAccountAsAdminInput = {
  name: Scalars['String'];
  roles?: InputMaybe<Array<Scalars['String']>>;
  users?: InputMaybe<Array<Scalars['String']>>;
  voices?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateAccountInput = {
  name: Scalars['String'];
  pricingPlan?: InputMaybe<CreateAccountWithPricingPlanInput>;
  user: Scalars['String'];
};

export type CreateAccountWithPricingPlanInput = {
  cancelUrl: Scalars['String'];
  pricingPlan: Scalars['String'];
  successUrl: Scalars['String'];
  trial: Scalars['Boolean'];
};

export type CreateApiTokenInput = {
  accounts: Array<Scalars['String']>;
  name: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type CreateAssignmentExistingVoiceInput = {
  voiceId: Scalars['String'];
};

export type CreateAssignmentInput = {
  content?: InputMaybe<Array<Scalars['String']>>;
  due?: InputMaybe<Scalars['Long']>;
  existingVoice?: InputMaybe<CreateAssignmentExistingVoiceInput>;
  newVoice?: InputMaybe<CreateAssignmentNewVoiceInput>;
  talent: Scalars['String'];
};

export type CreateAssignmentNewVoiceInput = {
  voiceTitle: Scalars['String'];
};

export type CreateClipInput = {
  project: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type CreateContentInput = {
  author?: InputMaybe<Scalars['String']>;
  csvPayload?: InputMaybe<Scalars['String']>;
  mediaItemId?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type CreateContentLineInput = {
  contentId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type CreateContentLineTalentReportInput = {
  contentLineId: Scalars['String'];
  reportReason: Scalars['String'];
  talentId: Scalars['String'];
};

export type CreateContentTagInput = {
  tag: Scalars['String'];
};

export type CreateMediaItemInput = {
  mimeType: Scalars['String'];
};

export type CreateMixInput = {
  project: Scalars['String'];
};

export type CreateMixTakeInput = {
  mix: Scalars['String'];
};

export type CreateMixTakeItemInput = {
  mix: Scalars['String'];
  takeId: Scalars['String'];
};

export type CreatePricingPlanInput = {
  description: Scalars['String'];
  featuresCopy: Scalars['String'];
  hidden: Scalars['Boolean'];
  name: Scalars['String'];
  roles?: InputMaybe<Array<Scalars['String']>>;
  stripePriceId?: InputMaybe<Scalars['String']>;
};

export type CreateProjectInput = {
  account: Scalars['String'];
  defaultVoice: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type CreateProjectTypeInput = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type CreateRecordingSessionContentLineItemInput = {
  contentLineId?: InputMaybe<Scalars['String']>;
  recordedContentLineText?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
};

export type CreateRecordingSessionInput = {
  assignment: Scalars['String'];
  browser?: InputMaybe<Scalars['String']>;
  operatingSystem?: InputMaybe<Scalars['String']>;
  recordingDeviceName?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
};

export type CreateRoleInput = {
  featureFlags?: InputMaybe<Array<UpsertRoleFeatureFlagInlineInput>>;
  maxPlaylists?: InputMaybe<Scalars['Long']>;
  maxProjects?: InputMaybe<Scalars['Long']>;
  name: Scalars['String'];
  synthesisApiAccess?: InputMaybe<Scalars['Boolean']>;
  synthesisMaxCharacters?: InputMaybe<Scalars['Long']>;
  synthesisMaxCharactersPerRequest?: InputMaybe<Scalars['Long']>;
  synthesisMaxRequests?: InputMaybe<Scalars['Long']>;
  synthesisRateLimit?: InputMaybe<Scalars['Long']>;
  voices?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateSubstitutionDictionaryMemberInput = {
  dictionary: Scalars['String'];
  original: Scalars['String'];
  pronunciation?: InputMaybe<Array<Scalars['String']>>;
  replacement?: InputMaybe<Scalars['String']>;
};

export type CreateTakeInput = {
  clip: Scalars['String'];
  requestRecordingByTalent?: InputMaybe<Scalars['Boolean']>;
  serializedTranscript: Scalars['String'];
  voice: Scalars['String'];
};

export type CreateTalentInput = {
  associateWithUser?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateUserAsAdminInput = {
  accounts: Array<Scalars['String']>;
  accountsWithTalentAccess?: InputMaybe<Array<Scalars['String']>>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  isTalent: Scalars['Boolean'];
  lastName: Scalars['String'];
};

export type CreateUserInput = {
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mailingListEmailAddress?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type CreateVoiceInput = {
  talent?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateVoiceVersionInput = {
  synthesisReference: Scalars['String'];
  version: Scalars['String'];
  voice: Scalars['String'];
};

export type DeleteApiTokenInput = {
  token: Scalars['String'];
};

export type DeleteClipInput = {
  id: Scalars['String'];
};

export type DeleteContentInput = {
  id: Scalars['String'];
};

export type DeleteContentLineInput = {
  id: Scalars['String'];
};

export type DeleteContentLineTalentReportsInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type DeleteMediaItemInput = {
  mediaItemId: Scalars['String'];
};

export type DeleteMixTakeInput = {
  mixTake: Scalars['String'];
};

export type DeletePlaylistInput = {
  mix: Scalars['String'];
};

export type DeleteProjectInput = {
  project: Scalars['String'];
};

export type DeleteProjectTypeInput = {
  id: Scalars['String'];
  replaceAllInstancesWith: Scalars['String'];
};

export type DeleteSubstitutionDictionaryMemberInput = {
  id: Scalars['String'];
};

export type DeleteTakeInput = {
  id: Scalars['String'];
};

export enum ETakeFlag {
  ThumbsDown = 'ThumbsDown',
  ThumbsUp = 'ThumbsUp'
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other'
}

export type IntRangeInput = {
  endInclusive: Scalars['Int'];
  start: Scalars['Int'];
};

export type MarkContentLineWithErrorInput = {
  error: Scalars['String'];
  id: Scalars['String'];
};

export type MergeVoicesInput = {
  deleteVersions?: InputMaybe<Array<Scalars['String']>>;
  renameVersions?: InputMaybe<Array<MergeVoicesVoiceVersionRenameInput>>;
  target: Scalars['String'];
  voices: Array<Scalars['String']>;
};

export type MergeVoicesVoiceVersionRenameInput = {
  renameTo: Scalars['String'];
  version: Scalars['String'];
};

export type MixContentInput = {
  ad?: InputMaybe<AdMixContentInput>;
  playlist?: InputMaybe<PlaylistMixContentInput>;
};

export enum MixFormat {
  AD = 'AD',
  PLAYLIST = 'PLAYLIST'
}

export type PaginationInput = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type PlaylistItemInput = {
  pause?: InputMaybe<PlaylistPauseItemInput>;
  take?: InputMaybe<PlaylistTakeItemInput>;
};

export type PlaylistMixContentInput = {
  items: Array<PlaylistItemInput>;
};

export type PlaylistPauseItemInput = {
  duration: Scalars['Int'];
  id: Scalars['String'];
};

export type PlaylistSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PlaylistTakeItemInput = {
  id: Scalars['String'];
  takeId: Scalars['String'];
};

export type PricingPlanSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PricingPlansQueryInput = {
  pagination: PaginationInput;
  viewAsSuperAdmin: Scalars['Boolean'];
};

export type ProjectSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProjectTypeSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum RecordingApprovalFlag {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Undecided = 'Undecided'
}

export type RecordingSessionContentLineItemSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type RecordingSessionContentLineItemsQueryInput = {
  pagination: PaginationInput;
};

export type RequestUserEmailVerificationInput = {
  id: Scalars['String'];
};

export enum RoleFeatureFlagKey {
  AllAppFeatures = 'AllAppFeatures',
  EditorInlinePronunciation = 'EditorInlinePronunciation',
  EditorSsml = 'EditorSsml',
  EditorTone = 'EditorTone',
  EditorWordPause = 'EditorWordPause',
  EditorWordStress = 'EditorWordStress',
  EnterpriseTalentPage = 'EnterpriseTalentPage',
  ProjectDictionary = 'ProjectDictionary',
  ProjectMix = 'ProjectMix',
  ProjectMixAdMode = 'ProjectMixAdMode',
  TakeDownload = 'TakeDownload'
}

export type RoleSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type RolesQueryInput = {
  pagination: PaginationInput;
};

export type SelectPricingPlanForAccountInput = {
  account: Scalars['String'];
  cancelUrl: Scalars['String'];
  pricingPlan: Scalars['String'];
  successUrl: Scalars['String'];
  trial: Scalars['Boolean'];
};

export type SetCustomPlanForAccountInput = {
  account: Scalars['String'];
};

export type TakeSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TalentFirstNameWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export enum TalentJobCategory {
  Animation_Dubbing = 'Animation_Dubbing',
  Audiobooks = 'Audiobooks',
  Cinema_Events = 'Cinema_Events',
  Digital_Broadcast = 'Digital_Broadcast',
  ELearning = 'ELearning',
  IVR_Message_on_Hold = 'IVR_Message_on_Hold',
  Non_Broadcast = 'Non_Broadcast',
  Promo_Imaging = 'Promo_Imaging',
  Radio_Broadcast = 'Radio_Broadcast',
  TV_Broadcast = 'TV_Broadcast',
  VideoGames_Toys_Games = 'VideoGames_Toys_Games'
}

export type TalentLastNameWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type TalentMultiWhereInput = {
  firstName?: InputMaybe<TalentFirstNameWhereInput>;
  lastName?: InputMaybe<TalentLastNameWhereInput>;
  name?: InputMaybe<TalentNameWhereInput>;
};

export type TalentNameWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type TalentSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TalentsQueryInput = {
  pagination: PaginationInput;
  where?: InputMaybe<TalentMultiWhereInput>;
};

export type UpdateAccountInput = {
  id: Scalars['String'];
  roles?: InputMaybe<UpdateAccountRolesInput>;
  talent: Array<Scalars['String']>;
  title: Scalars['String'];
  users: Array<Scalars['String']>;
  voiceVersions?: InputMaybe<Array<Scalars['String']>>;
  voices: Array<Scalars['String']>;
};

export type UpdateAccountRolesInput = {
  add?: InputMaybe<Array<Scalars['String']>>;
  remove?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateAssignmentInput = {
  assignmentId: Scalars['String'];
  content?: InputMaybe<Array<Scalars['String']>>;
  due?: InputMaybe<Scalars['Long']>;
  visibleToTalent?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateClipInput = {
  clip: Scalars['String'];
  title?: InputMaybe<UpdateClipTitleInput>;
};

export type UpdateClipTitleInput = {
  newTitle: Scalars['String'];
};

export type UpdateContentInput = {
  author?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lines?: InputMaybe<Array<UpdateContentLineInlineInput>>;
  mediaItemId?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateContentLineInlineInput = {
  id: Scalars['String'];
  index?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type UpdateMixMutationInput = {
  content: MixContentInput;
  id: Scalars['String'];
};

export type UpdatePricingPlanInput = {
  description?: InputMaybe<Scalars['String']>;
  featuresCopy?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  stripePriceId?: InputMaybe<UpdatePricingPlanStripePriceIdInput>;
  trial?: InputMaybe<UpdatePricingPlanTrialInput>;
};

export type UpdatePricingPlanStripePriceIdInput = {
  priceId?: InputMaybe<Scalars['String']>;
  updateAllRelatedSubscriptions: Scalars['Boolean'];
};

export type UpdatePricingPlanTrialInput = {
  description?: InputMaybe<Scalars['String']>;
  featuresCopy?: InputMaybe<Scalars['String']>;
  periodDays?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateProjectInput = {
  id: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateProjectTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRecordingSessionCalibrationItemInput = {
  audioUrl: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateRecordingSessionContentLineItemEnterpriseReviewCommentsInput = {
  comments?: InputMaybe<Scalars['String']>;
};

export type UpdateRecordingSessionContentLineItemInput = {
  audioUrl?: InputMaybe<Scalars['String']>;
  clientApprovalFlag?: InputMaybe<RecordingApprovalFlag>;
  enterpriseReviewComments?: InputMaybe<UpdateRecordingSessionContentLineItemEnterpriseReviewCommentsInput>;
  recordedContentLineText?: InputMaybe<Scalars['String']>;
  recordingId: Scalars['String'];
};

export type UpdateRoleInput = {
  accounts?: InputMaybe<Array<Scalars['String']>>;
  featureFlags?: InputMaybe<Array<UpsertRoleFeatureFlagInlineInput>>;
  id: Scalars['String'];
  keepRemovedVoicesOnAccounts?: InputMaybe<Scalars['Boolean']>;
  maxPlaylists?: InputMaybe<UpdateRoleMaxPlaylistsInput>;
  maxProjects?: InputMaybe<UpdateRoleMaxProjectsInput>;
  name?: InputMaybe<Scalars['String']>;
  synthesisApiAccess?: InputMaybe<UpdateRoleSynthesisApiAccessInput>;
  synthesisMaxCharacters?: InputMaybe<UpdateRoleSynthesisMaxCharactersInput>;
  synthesisMaxCharactersPerRequest?: InputMaybe<UpdateRoleSynthesisMaxCharactersPerRequestInput>;
  synthesisMaxRequests?: InputMaybe<UpdateRoleSynthesisMaxRequestsInput>;
  synthesisRateLimit?: InputMaybe<UpdateRoleSynthesisRateLimitInput>;
  voices?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateRoleMaxPlaylistsInput = {
  value?: InputMaybe<Scalars['Long']>;
};

export type UpdateRoleMaxProjectsInput = {
  value?: InputMaybe<Scalars['Long']>;
};

export type UpdateRoleSynthesisApiAccessInput = {
  value: Scalars['Boolean'];
};

export type UpdateRoleSynthesisMaxCharactersInput = {
  value?: InputMaybe<Scalars['Long']>;
};

export type UpdateRoleSynthesisMaxCharactersPerRequestInput = {
  value?: InputMaybe<Scalars['Long']>;
};

export type UpdateRoleSynthesisMaxRequestsInput = {
  value?: InputMaybe<Scalars['Long']>;
};

export type UpdateRoleSynthesisRateLimitInput = {
  value?: InputMaybe<Scalars['Long']>;
};

export type UpdateSubstitutionDictionaryMemberInput = {
  dictionary: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateTakeInput = {
  flag?: InputMaybe<UpdateTakeTakeFlagInput>;
  id: Scalars['String'];
};

export type UpdateTakeTakeFlagInput = {
  new?: InputMaybe<ETakeFlag>;
};

export type UpdateTalentInput = {
  age?: InputMaybe<Scalars['Int']>;
  allowableUseCases?: InputMaybe<Scalars['String']>;
  brandConflicts?: InputMaybe<Array<Scalars['String']>>;
  desiredProjectCategories?: InputMaybe<UpdateTalentOptionalJobCategoryListInput>;
  ethnicIdentity?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  hiredProjectCategories?: InputMaybe<UpdateTalentOptionalJobCategoryListInput>;
  id: Scalars['String'];
  interestedInCharacterVoiceRecording?: InputMaybe<UpdateTalentInterestedInCharacterVoiceRecordingInput>;
  interestedInNonenglishLanguageRecording?: InputMaybe<UpdateTalentInterestedInNonenglishLanguageRecordingInput>;
  interestedInRegionalAccentRecording?: InputMaybe<UpdateTalentInterestedInRegionalAccentRecordingInput>;
  lastName?: InputMaybe<Scalars['String']>;
  limitations?: InputMaybe<Scalars['String']>;
  memberOfUnion?: InputMaybe<Scalars['Boolean']>;
  otherJobInfo?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  showcaseVoicesOnVoice123?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTalentInterestedInCharacterVoiceRecordingInput = {
  list?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateTalentInterestedInNonenglishLanguageRecordingInput = {
  list?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateTalentInterestedInRegionalAccentRecordingInput = {
  list?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateTalentOptionalJobCategoryListInput = {
  list?: InputMaybe<Array<TalentJobCategory>>;
};

export type UpdateUserInput = {
  accounts?: InputMaybe<Array<Scalars['String']>>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateVoiceInput = {
  age?: InputMaybe<Scalars['Int']>;
  characterVoice?: InputMaybe<UpdateVoiceOptionalCharacterVoiceInput>;
  ethnicIdentity?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  id: Scalars['String'];
  nonenglishLanguage?: InputMaybe<UpdateVoiceOptionalNonenglishLanguageInput>;
  primaryVoiceVersionInput?: InputMaybe<UpdateVoicePrimaryVoiceVersionInput>;
  projectTypeWhitelist?: InputMaybe<UpdateVoiceProjectTypeWhitelistInput>;
  pronouns?: InputMaybe<Scalars['String']>;
  regionalAccent?: InputMaybe<UpdateVoiceOptionalRegionalAccentInput>;
  talentRecordingRequestsEnabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateVoiceOptionalCharacterVoiceInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateVoiceOptionalNonenglishLanguageInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateVoiceOptionalRegionalAccentInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateVoicePrimaryVoiceVersionInput = {
  version?: InputMaybe<Scalars['String']>;
};

export type UpdateVoiceProjectTypeWhitelistInput = {
  projectTypeIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UpsertRoleFeatureFlagInlineInput = {
  hidden: Scalars['Boolean'];
  key: RoleFeatureFlagKey;
  locked: Scalars['Boolean'];
  lockedReason?: InputMaybe<Scalars['String']>;
};

export type UserFirstNameWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type UserLastNameWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type UserMultiWhereInput = {
  firstName?: InputMaybe<UserFirstNameWhereInput>;
  lastName?: InputMaybe<UserLastNameWhereInput>;
  name?: InputMaybe<UserNameWhereInput>;
};

export type UserNameWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type UserSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type UsersQueryInput = {
  pagination: PaginationInput;
  where?: InputMaybe<UserMultiWhereInput>;
};

export type VoiceAgeWhereInput = {
  within?: InputMaybe<IntRangeInput>;
};

export type VoiceGenderWhereInput = {
  equals?: InputMaybe<Gender>;
};

export type VoiceMultiWhereInput = {
  age?: InputMaybe<VoiceAgeWhereInput>;
  gender?: InputMaybe<VoiceGenderWhereInput>;
  primaryVersion?: InputMaybe<VoicePrimaryVersionWhereInput>;
  projectTypeWhitelist?: InputMaybe<VoiceProjectTypeWhitelistWhereInput>;
  talent?: InputMaybe<VoiceTalentWhereInput>;
  title?: InputMaybe<VoiceTitleWhereInput>;
};

export type VoicePrimaryVersionWhereInput = {
  isNotNull?: InputMaybe<Scalars['Boolean']>;
};

export type VoiceProjectTypeWhitelistWhereInput = {
  includes?: InputMaybe<Array<Scalars['String']>>;
};

export type VoiceSingleWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type VoiceSortingInput = {
  age?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
};

export type VoiceTalentWhereInput = {
  equals?: InputMaybe<Scalars['String']>;
};

export type VoiceTitleWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type VoicesQueryInput = {
  pagination: PaginationInput;
  sorting?: InputMaybe<VoiceSortingInput>;
  where?: InputMaybe<VoiceMultiWhereInput>;
};

export type toggleTagOnMediaInput = {
  contentTagId: Scalars['String'];
  mediaItemId: Scalars['String'];
};

export type CreateContentLineTalentReportMutationVariables = Exact<{
  input: CreateContentLineTalentReportInput;
}>;


export type CreateContentLineTalentReportMutation = { createContentLineTalentReport: { __typename: 'CreateContentLineTalentReportSuccess', contentLineTalentReport: { __typename: 'ContentLineTalentReport', id: string } } };

export type CreateRecordingSessionMutationVariables = Exact<{
  input: CreateRecordingSessionInput;
}>;


export type CreateRecordingSessionMutation = { createRecordingSession: { __typename: 'RecordingSession', id: string, backgroundNoiseCalibrationItem: { __typename: 'RecordingSessionCalibrationItem', id: string, uploadUrl: string }, talentVolumeCalibrationItem: { __typename: 'RecordingSessionCalibrationItem', id: string, uploadUrl: string } } };

export type CreateRecordingSessionContentLineItemMutationVariables = Exact<{
  contentLineId: Scalars['String'];
  sessionId: Scalars['String'];
}>;


export type CreateRecordingSessionContentLineItemMutation = { createRecordingSessionContentLineItem: { __typename: 'RecordingSessionContentLineItem', id: string, uploadUrl: string | null } };

export type UpdateRecordingSessionCalibrationItemMutationVariables = Exact<{
  audioUrl: Scalars['String'];
  calibrationItemId: Scalars['String'];
}>;


export type UpdateRecordingSessionCalibrationItemMutation = { updateRecordingSessionCalibrationItem: { __typename: 'RecordingSessionCalibrationItem', id: string, audioUrl: string | null, result: string | null } };

export type UpdateRecordingSessionContentLineItemMutationVariables = Exact<{
  audioUrl: Scalars['String'];
  recordingId: Scalars['String'];
}>;


export type UpdateRecordingSessionContentLineItemMutation = { updateRecordingSessionContentLineItem: { __typename: 'RecordingSessionContentLineItem', id: string, audioUrl: string | null } };

export type ContentLinesQueryVariables = Exact<{
  assignmentId: Scalars['String'];
  contentId: Scalars['String'];
}>;


export type ContentLinesQuery = { content: { __typename: 'Content', id: string, title: string | null, author: string | null, lines: Array<{ __typename: 'ContentLine', id: string, text: string, directorsNotes: string | null, error: string | null, recording: { __typename: 'RecordingSessionContentLineItem', id: string, audioUrl: string | null, uploadUrl: string | null } | null, talentReports: Array<{ __typename: 'ContentLineTalentReport', id: string, reportedByTalent: { __typename: 'Talent', id: string } }>, talentRecordingRequestTake: { __typename: 'GeneratedTake', id: string, voice: { __typename: 'Voice', id: string, title: string }, clip: { __typename: 'Clip', title: string | null, project: { __typename: 'Project', title: string, account: { __typename: 'Account', id: string, name: string } | null } | null } | null } | { __typename: 'TalentRecordedTake', id: string, voice: { __typename: 'Voice', id: string, title: string }, clip: { __typename: 'Clip', title: string | null, project: { __typename: 'Project', title: string, account: { __typename: 'Account', id: string, name: string } | null } | null } | null } | null }> } };

export type CurrentAssignmentDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type CurrentAssignmentDetailsQuery = { viewer: { __typename: 'MachineClientViewer', user: { __typename: 'User', id: string, emailAddress: string, firstName: string, lastName: string, talent: { __typename: 'TalentUser', id: string, talent: { __typename: 'Talent', id: string, currentAssignment: { __typename: 'Assignment', id: string, isTalentRecordingRequestTarget: boolean, currentContent: { __typename: 'CurrentContent', content: { __typename: 'Content', id: string } | null } | null, voice: { __typename: 'Voice', id: string, title: string } | null } | null } } | null } } | { __typename: 'WebClientViewer', user: { __typename: 'User', id: string, emailAddress: string, firstName: string, lastName: string, talent: { __typename: 'TalentUser', id: string, talent: { __typename: 'Talent', id: string, currentAssignment: { __typename: 'Assignment', id: string, isTalentRecordingRequestTarget: boolean, currentContent: { __typename: 'CurrentContent', content: { __typename: 'Content', id: string } | null } | null, voice: { __typename: 'Voice', id: string, title: string } | null } | null } } | null } } | null };

export type NextContentQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type NextContentQuery = { viewer: { __typename: 'MachineClientViewer', user: { __typename: 'User', talent: { __typename: 'TalentUser', id: string, talent: { __typename: 'Talent', id: string, currentAssignment: { __typename: 'Assignment', id: string, currentContent: { __typename: 'CurrentContent', nextOptions: Array<{ __typename: 'Content', id: string, title: string | null, author: string | null, totalRecordingsCount: number, countOfLines: number }> } | null } | null } } | null } } | { __typename: 'WebClientViewer', user: { __typename: 'User', talent: { __typename: 'TalentUser', id: string, talent: { __typename: 'Talent', id: string, currentAssignment: { __typename: 'Assignment', id: string, currentContent: { __typename: 'CurrentContent', nextOptions: Array<{ __typename: 'Content', id: string, title: string | null, author: string | null, totalRecordingsCount: number, countOfLines: number }> } | null } | null } } | null } } | null };


export const CreateContentLineTalentReportMutationDocument = gql`
    mutation CreateContentLineTalentReportMutation($input: CreateContentLineTalentReportInput!) {
  createContentLineTalentReport(input: $input) {
    contentLineTalentReport {
      id
    }
  }
}
    `;
export type CreateContentLineTalentReportMutationMutationFn = Apollo.MutationFunction<CreateContentLineTalentReportMutation, CreateContentLineTalentReportMutationVariables>;

/**
 * __useCreateContentLineTalentReportMutation__
 *
 * To run a mutation, you first call `useCreateContentLineTalentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentLineTalentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentLineTalentReportMutation, { data, loading, error }] = useCreateContentLineTalentReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContentLineTalentReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentLineTalentReportMutation, CreateContentLineTalentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentLineTalentReportMutation, CreateContentLineTalentReportMutationVariables>(CreateContentLineTalentReportMutationDocument, options);
      }
export type CreateContentLineTalentReportMutationHookResult = ReturnType<typeof useCreateContentLineTalentReportMutation>;
export type CreateContentLineTalentReportMutationMutationResult = Apollo.MutationResult<CreateContentLineTalentReportMutation>;
export type CreateContentLineTalentReportMutationMutationOptions = Apollo.BaseMutationOptions<CreateContentLineTalentReportMutation, CreateContentLineTalentReportMutationVariables>;
export const CreateRecordingSessionMutationDocument = gql`
    mutation CreateRecordingSessionMutation($input: CreateRecordingSessionInput!) {
  createRecordingSession(input: $input) {
    id
    backgroundNoiseCalibrationItem {
      id
      uploadUrl
    }
    talentVolumeCalibrationItem {
      id
      uploadUrl
    }
  }
}
    `;
export type CreateRecordingSessionMutationMutationFn = Apollo.MutationFunction<CreateRecordingSessionMutation, CreateRecordingSessionMutationVariables>;

/**
 * __useCreateRecordingSessionMutation__
 *
 * To run a mutation, you first call `useCreateRecordingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingSessionMutation, { data, loading, error }] = useCreateRecordingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecordingSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecordingSessionMutation, CreateRecordingSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecordingSessionMutation, CreateRecordingSessionMutationVariables>(CreateRecordingSessionMutationDocument, options);
      }
export type CreateRecordingSessionMutationHookResult = ReturnType<typeof useCreateRecordingSessionMutation>;
export type CreateRecordingSessionMutationMutationResult = Apollo.MutationResult<CreateRecordingSessionMutation>;
export type CreateRecordingSessionMutationMutationOptions = Apollo.BaseMutationOptions<CreateRecordingSessionMutation, CreateRecordingSessionMutationVariables>;
export const CreateRecordingSessionContentLineItemMutationDocument = gql`
    mutation CreateRecordingSessionContentLineItemMutation($contentLineId: String!, $sessionId: String!) {
  createRecordingSessionContentLineItem(
    input: {contentLineId: $contentLineId, sessionId: $sessionId}
  ) {
    id
    uploadUrl
  }
}
    `;
export type CreateRecordingSessionContentLineItemMutationMutationFn = Apollo.MutationFunction<CreateRecordingSessionContentLineItemMutation, CreateRecordingSessionContentLineItemMutationVariables>;

/**
 * __useCreateRecordingSessionContentLineItemMutation__
 *
 * To run a mutation, you first call `useCreateRecordingSessionContentLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingSessionContentLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingSessionContentLineItemMutation, { data, loading, error }] = useCreateRecordingSessionContentLineItemMutation({
 *   variables: {
 *      contentLineId: // value for 'contentLineId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCreateRecordingSessionContentLineItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecordingSessionContentLineItemMutation, CreateRecordingSessionContentLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecordingSessionContentLineItemMutation, CreateRecordingSessionContentLineItemMutationVariables>(CreateRecordingSessionContentLineItemMutationDocument, options);
      }
export type CreateRecordingSessionContentLineItemMutationHookResult = ReturnType<typeof useCreateRecordingSessionContentLineItemMutation>;
export type CreateRecordingSessionContentLineItemMutationMutationResult = Apollo.MutationResult<CreateRecordingSessionContentLineItemMutation>;
export type CreateRecordingSessionContentLineItemMutationMutationOptions = Apollo.BaseMutationOptions<CreateRecordingSessionContentLineItemMutation, CreateRecordingSessionContentLineItemMutationVariables>;
export const UpdateRecordingSessionCalibrationItemMutationDocument = gql`
    mutation UpdateRecordingSessionCalibrationItemMutation($audioUrl: String!, $calibrationItemId: String!) {
  updateRecordingSessionCalibrationItem(
    input: {audioUrl: $audioUrl, id: $calibrationItemId}
  ) {
    id
    audioUrl
    result
  }
}
    `;
export type UpdateRecordingSessionCalibrationItemMutationMutationFn = Apollo.MutationFunction<UpdateRecordingSessionCalibrationItemMutation, UpdateRecordingSessionCalibrationItemMutationVariables>;

/**
 * __useUpdateRecordingSessionCalibrationItemMutation__
 *
 * To run a mutation, you first call `useUpdateRecordingSessionCalibrationItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecordingSessionCalibrationItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecordingSessionCalibrationItemMutation, { data, loading, error }] = useUpdateRecordingSessionCalibrationItemMutation({
 *   variables: {
 *      audioUrl: // value for 'audioUrl'
 *      calibrationItemId: // value for 'calibrationItemId'
 *   },
 * });
 */
export function useUpdateRecordingSessionCalibrationItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecordingSessionCalibrationItemMutation, UpdateRecordingSessionCalibrationItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecordingSessionCalibrationItemMutation, UpdateRecordingSessionCalibrationItemMutationVariables>(UpdateRecordingSessionCalibrationItemMutationDocument, options);
      }
export type UpdateRecordingSessionCalibrationItemMutationHookResult = ReturnType<typeof useUpdateRecordingSessionCalibrationItemMutation>;
export type UpdateRecordingSessionCalibrationItemMutationMutationResult = Apollo.MutationResult<UpdateRecordingSessionCalibrationItemMutation>;
export type UpdateRecordingSessionCalibrationItemMutationMutationOptions = Apollo.BaseMutationOptions<UpdateRecordingSessionCalibrationItemMutation, UpdateRecordingSessionCalibrationItemMutationVariables>;
export const UpdateRecordingSessionContentLineItemMutationDocument = gql`
    mutation UpdateRecordingSessionContentLineItemMutation($audioUrl: String!, $recordingId: String!) {
  updateRecordingSessionContentLineItem(
    input: {audioUrl: $audioUrl, recordingId: $recordingId}
  ) {
    id
    audioUrl
  }
}
    `;
export type UpdateRecordingSessionContentLineItemMutationMutationFn = Apollo.MutationFunction<UpdateRecordingSessionContentLineItemMutation, UpdateRecordingSessionContentLineItemMutationVariables>;

/**
 * __useUpdateRecordingSessionContentLineItemMutation__
 *
 * To run a mutation, you first call `useUpdateRecordingSessionContentLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecordingSessionContentLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecordingSessionContentLineItemMutation, { data, loading, error }] = useUpdateRecordingSessionContentLineItemMutation({
 *   variables: {
 *      audioUrl: // value for 'audioUrl'
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useUpdateRecordingSessionContentLineItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecordingSessionContentLineItemMutation, UpdateRecordingSessionContentLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecordingSessionContentLineItemMutation, UpdateRecordingSessionContentLineItemMutationVariables>(UpdateRecordingSessionContentLineItemMutationDocument, options);
      }
export type UpdateRecordingSessionContentLineItemMutationHookResult = ReturnType<typeof useUpdateRecordingSessionContentLineItemMutation>;
export type UpdateRecordingSessionContentLineItemMutationMutationResult = Apollo.MutationResult<UpdateRecordingSessionContentLineItemMutation>;
export type UpdateRecordingSessionContentLineItemMutationMutationOptions = Apollo.BaseMutationOptions<UpdateRecordingSessionContentLineItemMutation, UpdateRecordingSessionContentLineItemMutationVariables>;
export const ContentLinesQueryDocument = gql`
    query ContentLinesQuery($assignmentId: String!, $contentId: String!) {
  content(where: {id: $contentId}) {
    id
    title
    author
    lines {
      id
      text
      directorsNotes
      error
      recording(assignmentId: $assignmentId) {
        id
        audioUrl
        uploadUrl
      }
      talentReports {
        id
        reportedByTalent {
          id
        }
      }
      talentRecordingRequestTake {
        id
        voice {
          id
          title
        }
        clip {
          title
          project {
            title
            account {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useContentLinesQuery__
 *
 * To run a query within a React component, call `useContentLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentLinesQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useContentLinesQuery(baseOptions: Apollo.QueryHookOptions<ContentLinesQuery, ContentLinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentLinesQuery, ContentLinesQueryVariables>(ContentLinesQueryDocument, options);
      }
export function useContentLinesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentLinesQuery, ContentLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentLinesQuery, ContentLinesQueryVariables>(ContentLinesQueryDocument, options);
        }
export type ContentLinesQueryHookResult = ReturnType<typeof useContentLinesQuery>;
export type ContentLinesQueryLazyQueryHookResult = ReturnType<typeof useContentLinesQueryLazyQuery>;
export type ContentLinesQueryQueryResult = Apollo.QueryResult<ContentLinesQuery, ContentLinesQueryVariables>;
export function refetchContentLinesQuery(variables: ContentLinesQueryVariables) {
      return { query: ContentLinesQueryDocument, variables: variables }
    }
export const CurrentAssignmentDetailsQueryDocument = gql`
    query CurrentAssignmentDetailsQuery($id: String) {
  viewer {
    user {
      id
      emailAddress
      firstName
      lastName
      talent {
        id
        talent {
          id
          currentAssignment(where: {id: $id}) {
            id
            currentContent {
              content {
                id
              }
            }
            isTalentRecordingRequestTarget
            voice {
              id
              title
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentAssignmentDetailsQuery__
 *
 * To run a query within a React component, call `useCurrentAssignmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAssignmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAssignmentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrentAssignmentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAssignmentDetailsQuery, CurrentAssignmentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentAssignmentDetailsQuery, CurrentAssignmentDetailsQueryVariables>(CurrentAssignmentDetailsQueryDocument, options);
      }
export function useCurrentAssignmentDetailsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAssignmentDetailsQuery, CurrentAssignmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentAssignmentDetailsQuery, CurrentAssignmentDetailsQueryVariables>(CurrentAssignmentDetailsQueryDocument, options);
        }
export type CurrentAssignmentDetailsQueryHookResult = ReturnType<typeof useCurrentAssignmentDetailsQuery>;
export type CurrentAssignmentDetailsQueryLazyQueryHookResult = ReturnType<typeof useCurrentAssignmentDetailsQueryLazyQuery>;
export type CurrentAssignmentDetailsQueryQueryResult = Apollo.QueryResult<CurrentAssignmentDetailsQuery, CurrentAssignmentDetailsQueryVariables>;
export function refetchCurrentAssignmentDetailsQuery(variables?: CurrentAssignmentDetailsQueryVariables) {
      return { query: CurrentAssignmentDetailsQueryDocument, variables: variables }
    }
export const NextContentQueryDocument = gql`
    query NextContentQuery($assignmentId: String!) {
  viewer {
    user {
      talent {
        id
        talent {
          id
          currentAssignment(where: {id: $assignmentId}) {
            id
            currentContent {
              nextOptions {
                id
                title
                author
                totalRecordingsCount(assignmentId: $assignmentId, includeErrorLines: false)
                countOfLines(includeErrorLines: false)
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useNextContentQuery__
 *
 * To run a query within a React component, call `useNextContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextContentQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useNextContentQuery(baseOptions: Apollo.QueryHookOptions<NextContentQuery, NextContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextContentQuery, NextContentQueryVariables>(NextContentQueryDocument, options);
      }
export function useNextContentQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextContentQuery, NextContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextContentQuery, NextContentQueryVariables>(NextContentQueryDocument, options);
        }
export type NextContentQueryHookResult = ReturnType<typeof useNextContentQuery>;
export type NextContentQueryLazyQueryHookResult = ReturnType<typeof useNextContentQueryLazyQuery>;
export type NextContentQueryQueryResult = Apollo.QueryResult<NextContentQuery, NextContentQueryVariables>;
export function refetchNextContentQuery(variables: NextContentQueryVariables) {
      return { query: NextContentQueryDocument, variables: variables }
    }