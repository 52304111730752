import React, {ErrorInfo} from "react";
import Tile from "../../atoms/tile";
import { css } from "emotion";
import classNames from "classnames";
import FullscreenMessage from '../../atoms/fullscreen-message';

type Err = string | { message: string; payload: string } | null

interface IGenericErrorBoundaryProps {
  error?: Err;
}

interface IGenericErrorBoundaryState {
  error: Err;
  showErrorDetails: boolean;
}

export default class GenericErrorBoundary extends React.Component<IGenericErrorBoundaryProps, IGenericErrorBoundaryState> {
  constructor(props: IGenericErrorBoundaryProps) {
    super(props);
    this.state = { error: null, showErrorDetails: false };
  }

  static getDerivedStateFromError(error: Error): IGenericErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { error: error.message, showErrorDetails: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

  render() {
    const err = this.state.error || this.props.error

    if (err) {
      // You can render any custom fallback UI
      return <FullscreenMessage heading={"An error has occurred"}>
          Please refresh the page.
          <div className={"mt4"}>
            <div className={"pointer vocalid-primary-text"} onClick={() => this.setState({ showErrorDetails: !this.state.showErrorDetails })}>{!this.state.showErrorDetails ? 'See' : 'Hide' } error details</div>
            {this.state.showErrorDetails && (typeof err === "string" ? <div className={"mv2"}><code className={css({
              wordBreak: 'break-all'
            })}>{"> "}{err}</code></div> : <div>
              <div>{err.message}</div>
              <div>{err.payload}</div>
            </div>) }
          </div>
      </FullscreenMessage>
    }

    return this.props.children;
  }
}
