import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import ProfileDropdown from '../../molecules/profile-dropdown';
import Avatar from '../../atoms/avatar';
import { useTheme } from '../../../theme';

export interface IAppTemplateProps {
  data: {
    user: {
      firstName: string;
      lastName: string;
      imageUrl: string;
      email: string;
    };
  };
  onSignOut(): void;
  className?: string;
}

const AppTemplate: React.FC<IAppTemplateProps> = (props) => {
  const { colors: themeColors } = useTheme();

  return (
    <div
      className={classNames(
        'flex flex-column h-100 bg-vocalid-page vocalid-body',
        props.className
      )}
    >
      <nav
        className={classNames(
          "mt2",
          css({ borderWidth: 1 })
        )}
      >
        <div className={'center flex flex-shrink-0 items-center justify-between pv2 pl5 pr4'}>
          <div className={'flex items-center'}>
            <span
              className={classNames(
                css({
                  width: 40
                }),
                'dib mr4 flex justify-center flex-shrink-0'
              )}
            >
              <img
                src={'/logo-icon.png'}
                alt={'VocaliD Icon'}
                height={40}
                width={'auto'}
                className={'flex-shrink-0'}
              />
            </span>
          </div>
          <div className={'pr3'}>
            <ProfileDropdown
              profile={props.data.user}
              profileUrl={'/profile'}
              onSignOut={props.onSignOut}
            >
              <span className={'vocalid-secondary-text'}>
                <span className={'div v-mid mr3'}>{props.data.user.firstName}</span>
                <Avatar className={'v-mid'} bgColor={themeColors.red}>
                  {`${props.data.user.firstName.substring(
                    0,
                    1
                  )}${props.data.user.lastName.substring(0, 2)}`}
                </Avatar>
              </span>
            </ProfileDropdown>
          </div>
        </div>
      </nav>
      <div className={classNames('flex-grow-1 overflow-y-auto relative', css({ maxHeight: '100%'}))}>{props.children}</div>
    </div>
  );
};

export default AppTemplate;
