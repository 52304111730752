import React from 'react';
import KeyboardPanel from "../../molecules/keyboard-panel";
import {KeyboardPanelProxyContextProvider} from "../../molecules/keyboard-panel/context";

export interface IHomeTemplateProps {

}

const HomeTemplate: React.FC<IHomeTemplateProps> = props => {
  return (
    <KeyboardPanelProxyContextProvider>
      <div className={'relative flex flex-column h-100'}>
        <div className={'z-0 flex-grow-1 flex-shrink-1 overflow-y-auto flex justify-center items-start'}>
          {props.children}
        </div>
        <div className={"z-1"}>
          <KeyboardPanel />
        </div>
      </div>
    </KeyboardPanelProxyContextProvider>
  );
};

export default HomeTemplate;
