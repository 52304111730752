import React from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { useQueryParam, StringParam } from 'use-query-params';
import { useCurrentAssignmentDetailsQuery } from '../../../../graphql/generated';

export interface CurrentUser {
  id: string;
  talentId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  requestedAssignmentId: string | null;
  assignment: CurrentAssignment | null;
}

export interface CurrentAssignment {
  id: string;
  title: string;
  contentId: string | null;
  setContentId(id: string): void;
}

interface ICurrentAssignmentContext {
  user: CurrentUser | null;
  loading: boolean;
  error: ApolloError | null;
}

const AppContext = React.createContext<ICurrentAssignmentContext>({
  user: null,
  loading: true,
  error: null
})

export const AppContextProvider: React.FC = props => {
  const [qsAssignmentId] = useQueryParam('assignment', StringParam);
  React.useEffect(() => {
    console.log(`Passed query params assignment id: ${qsAssignmentId}`);
  }, [qsAssignmentId]);

  // assignment
  const assignmentDetailsQuery = useCurrentAssignmentDetailsQuery({ variables: { id: qsAssignmentId ?? null }, fetchPolicy: 'network-only' });
  const viewer = assignmentDetailsQuery.data?.viewer?.user;
  const assignment = viewer?.talent?.talent.currentAssignment ?? null;

  const resolvedAssignmentId = assignment?.id;
  React.useEffect(() => {
    console.log(`Resolved Assignment id: ${resolvedAssignmentId ?? "null"}`);
  }, [resolvedAssignmentId]);


  const assignmentDerivedCurrentContentId = assignment?.currentContent?.content?.id ?? null;

  // content
  const [currentContentId, setCurrentContentId] = React.useState<CurrentAssignment['contentId'] | null>(assignmentDerivedCurrentContentId);
  React.useEffect(() => {
    setCurrentContentId(assignmentDerivedCurrentContentId);
  }, [assignmentDerivedCurrentContentId]);

  // loading state
  if (assignmentDetailsQuery.loading) {
    return <div className={"vocalid-primary-text flex justify-center pt6 h-100"}><CircularProgress color={"inherit"} /></div>;
  }

  return <AppContext.Provider value={{
    user: viewer ? {
      id: viewer.id,
      talentId: viewer.talent!.talent.id,
      firstName: viewer.firstName,
      lastName: viewer.lastName,
      emailAddress: viewer.emailAddress,
      requestedAssignmentId: qsAssignmentId ?? null,
      assignment: assignment ? {
        id: assignment.id,
        title: assignment.isTalentRecordingRequestTarget ? `Pick up${assignment.voice?.title ? ` (${assignment.voice.title})` : ""}` : assignment.voice?.title ?? "Untitled Assignment",
        contentId: currentContentId,
        setContentId: id => setCurrentContentId(id)
      } : null
    } : null,
    loading: assignmentDetailsQuery.loading,
    error: assignmentDetailsQuery.error ?? null
  }}>
    {props.children}
  </AppContext.Provider>
}

export const useAppContext = () => React.useContext(AppContext);

export const useAssertedCurrentAssignment = (): CurrentAssignment => {
  const history = useHistory();
  const assignment = useAppContext().user?.assignment;

  if (!assignment) {
    console.log("No assignment loaded (while asserted), redirecting to /confirm-user...");
    history.push(`/confirm-user${window.location.search}`);
    // @ts-ignore
    return;
  }

  return assignment;
}
