import React from 'react';
import CalibrationPage, {ICalibrationPageProps} from "../../components/pages/calibration";
import { useCurrentSession } from '../shared/context/app-state/current-session-context';
import { useAssertedCurrentAssignment } from '../shared/context/app-state/app-context';
import { useUpdateRecordingSessionCalibrationItemMutation } from '../../graphql/generated';

export interface ICalibrationPageContainerProps {
  onBack(): void;
  onNext(): void;
}

const CalibrationPageContainer: React.FC<ICalibrationPageContainerProps> = props => {
  useAssertedCurrentAssignment()

  const uploadCalibrationItem = useCalibrationRecording();

  const [backgroundNoiseState, setBackgroundNoiseState] = React.useState<ICalibrationPageProps["backgroundNoiseState"]>('start');
  const [micVolumeState, setMicVolumeState] = React.useState<ICalibrationPageProps["micVolumeState"]>('start');

  return (
    <CalibrationPage
      backgroundNoiseState={backgroundNoiseState}
      onBackgroundNoiseSample={async (recording) => {
        setBackgroundNoiseState('processing');
        const result = await uploadCalibrationItem("backgroundNoise", recording);
        console.log(`Result: ${result}`);

        setBackgroundNoiseState(result !== null ? {
          failed: true,
          recording
        } : 'succeeded')
      }}
      onBackgroundNoiseResetState={() => {
        setBackgroundNoiseState('start');
      }}

      micVolumeState={micVolumeState}
      onMicVolumeSample={async (recording) => {
        setMicVolumeState('processing');
        const result = await uploadCalibrationItem("talentVolume", recording);
        console.log(`Result: ${result}`);

        setMicVolumeState(result !== null ? {
          failed: true,
          reason: result,
          recording
        } : 'succeeded')
      }}
      onMicVolumeResetState={() => {
        setMicVolumeState('start');
      }}

      onBack={props.onBack} onNext={props.onNext} />
  );
};

export default CalibrationPageContainer;

const useCalibrationRecording = () => {
  const session = useCurrentSession();

  const [updateCalibrationItemMutation] = useUpdateRecordingSessionCalibrationItemMutation();
  const [uploadingCalibrationItem, setUploadingCalibrationItem] = React.useState(false);

  return (calibrationItemTitle: keyof NonNullable<typeof session>["calibration"], recording: Blob) => {
    const calibrationItem = session?.calibration[calibrationItemTitle];
    // console.log(recording);

    if (!calibrationItem) {
      throw new Error(`No calibration item with title ${calibrationItemTitle} returned from session`);
    }

    return fetch(calibrationItem.uploadUrl, {
      method: 'put',
      body: recording
    })
      .then(() => {
        // console.log("Upload successful");
        const parsedUploadUrl = new URL(calibrationItem.uploadUrl);

        return parsedUploadUrl.origin + parsedUploadUrl.pathname;
      }).then(audioUrl => {
        return updateCalibrationItemMutation({ variables: {
            calibrationItemId: calibrationItem.id,
            audioUrl: audioUrl
          }});
      }).then(res => {
        // console.log(res.data);
        return res.data!.updateRecordingSessionCalibrationItem.result
      });
  }
}
