import React from "react";
import Recorder, {ERecorderEvent, RecorderState, RecordingCompleteCallback} from "../../index";

interface IRecorderContext {
  recorder: Recorder | null;
  state: RecorderState;
  inputDevices: Array<MediaDeviceInfo>;
  currentInputDeviceId: string | null;
}

const RecorderContext = React.createContext<IRecorderContext>({
  recorder: null,
  state: 'not-initialized',
  inputDevices: [],
  currentInputDeviceId: null
});

export const RecorderProvider: React.FC = props => {
  const { recorder } = React.useContext(RecorderContext);
  const [state, setState] = React.useState<RecorderState>(recorder?.getState() ?? 'not-initialized');
  const [inputDevices, setInputDevices] = React.useState<Array<MediaDeviceInfo>>([]);
  const [currentInputDeviceId, setCurrentInputDeviceId] = React.useState<string | null>(null);

  if (recorder !== null) { throw new Error("There can be only one RecorderProvider in the render tree at a time") }

  const recorderRef = React.useRef(new Recorder())

  // input devices
  React.useEffect(() => {
    if (recorderRef.current) {
      recorderRef.current.getInputDevices().then(devices => {
        setInputDevices(devices)
        if (currentInputDeviceId === null && devices[0]) {
          recorderRef.current.close()
          recorderRef.current.initialize(devices[0]?.deviceId);
        }
      })
    }
  }, []);

  React.useEffect(() => {
    const listener = () => {
      recorderRef.current?.getInputDevices().then(devices => {
        setInputDevices(devices)
      });
      setCurrentInputDeviceId(recorderRef.current?.setup?.currentInputDevice.deviceId ?? null);
    };
    recorderRef.current?.addListener(ERecorderEvent.INITIAlIZED, listener);

    return () => {
      recorderRef.current?.removeListener(ERecorderEvent.INITIAlIZED, listener);
    }
  }, [recorderRef.current])

  // state changes
  React.useEffect(() => {
    const listener = setState;
    recorderRef.current?.addListener(ERecorderEvent.STATE_CHANGED, listener);

    return () => {
      recorderRef.current?.removeListener(ERecorderEvent.STATE_CHANGED, listener);
    }
  }, [recorderRef.current])

  return <RecorderContext.Provider value={{
    recorder: recorderRef.current,
    state,
    inputDevices,
    currentInputDeviceId
  }}>
    { props.children }
  </RecorderContext.Provider>
}

export default function useRecorder(onRecordingComplete?: RecordingCompleteCallback) {
  const { recorder, state, inputDevices, currentInputDeviceId } = React.useContext(RecorderContext);

  // recording completion
  React.useEffect(() => {
    const listener = (recording: Blob) => {
      onRecordingComplete?.(recording);
    };
    recorder?.addListener(ERecorderEvent.RECORDING_COMPLETE, listener);

    return () => {
      recorder?.removeListener(ERecorderEvent.RECORDING_COMPLETE, listener);
    }
  }, [onRecordingComplete])

  return {
    async startRecording() {
      await recorder!.startRecording()
    },
    stopRecording() {
      recorder!.stopRecording()
    },
    close() {
      recorder!.close()
    },
    getRecordingSetup() {
      return recorder!.setup;
    },
    state,
    inputDevices,
    currentInputDevice: (inputDevices ?? []).find(d => d.deviceId === currentInputDeviceId),
    setCurrentInputDeviceId: (newDeviceId: string) => {
      recorder?.close();
      recorder?.initialize(newDeviceId);
    }
  }
}

export function useRecorderContext() {
  return React.useContext(RecorderContext);
}
