import React from 'react';
import classNames from "classnames";
import {css} from "emotion";

export interface ISetupTileProps {
  imageUrl: string;
  className?: string;
}

const SetupTile: React.FC<ISetupTileProps> = props => {
  return (
    <div className={classNames(
      'bg-vocalid-tile',
      css({
        borderRadius: 6
      }), props.className)}>
      <div className={classNames("flex", css({
        height: 564,
        width: 1072
      }))}>
        <div className={classNames("flex-grow-0 flex-shrink-0", css({
          width: 500,
          height: '100%',
          background: `url(${props.imageUrl}) no-repeat`,
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6
        }))} />
        <div className={"flex-grow-1 flex flex-column pa4"}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SetupTile;
