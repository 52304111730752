import React from 'react';
import StorySelectionPage from "../../components/pages/story-selection";
import { CircularProgress } from '@material-ui/core';
import { useAssertedCurrentAssignment } from '../shared/context/app-state/app-context';
import { useNextContentQuery } from '../../graphql/generated';

export interface IStorySelectionPageContainerProps {
  onSelectedStory(storyId: string): void;
}

const StorySelectionPageContainer: React.FC<IStorySelectionPageContainerProps> = props => {
  const assignment = useAssertedCurrentAssignment();

  const nextContentData = useNextContentQuery({ variables: { assignmentId: assignment.id! }, skip: !assignment.id, fetchPolicy: 'no-cache' });
  const assignmentData = nextContentData.data?.viewer?.user.talent?.talent.currentAssignment;
  const options = assignmentData?.currentContent?.nextOptions ?? [];

  if (nextContentData.loading || !assignmentData) return <span className={"vocalid-primary-text"}><CircularProgress color={"inherit"} /></span>

  const storyOptions = options.map(o => ({
    id: o!.id,
    title: o!.title!,
    author: o!.author!,
    image: null,
    recordedLines: o!.totalRecordingsCount,
    totalLines: o!.countOfLines
  }))

  return (
    <StorySelectionPage storyOptions={storyOptions} onSelectedStory={props.onSelectedStory} />
  );
};

export default StorySelectionPageContainer;
