import React from 'react';

export interface IRoundedBannerProps {}

const RoundedBanner: React.FC<IRoundedBannerProps> = (props) => {
  return (
    <div className={'bg-vocalid-banner pv3 vocalid-body vocalid-dark-text relative br3'}>
      <div className={'ph3 tc'}>{props.children}</div>
    </div>
  );
};

export default RoundedBanner;
