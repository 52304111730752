import * as React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { useAuth } from "../auth";
import { onError } from '@apollo/client/link/error';
import GenericErrorBoundary from '../components/molecules/generic-error-boundary';
import fragmentMatcher from './generated_fragments';
import FullPageLoader from '../components/shared/loader';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes
})

export const GraphQLProvider: React.FC = (props) => {
  const [initialized, setInitialized] = React.useState(false);
  const { accessToken, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth();

  React.useEffect(
    () => {
      if (!isLoading) {
        (async () => {
          // initialize (get access token silently if authenticated or login)
          if (!isAuthenticated) {
            await loginWithRedirect(window.location.pathname + window.location.search);
          } else if (isAuthenticated) {
            await getAccessTokenSilently();
          }

          setInitialized(true);
        })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]
  )

  const [errors, setErrors] = React.useState<readonly Error[]>([]);

  if (!initialized || isLoading || accessToken === null) return <FullPageLoader />;

  const httpLink = new HttpLink({
    uri: `${API_BASE_URL}/graphql`,
    headers: {
      ...accessToken ? {
        "X-Vocalid-Parrot-Access-Token": accessToken
      } : {}
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors && graphQLErrors.length !== 0) {
      console.log("Graphql errors")
      console.log(graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      });
      setErrors(graphQLErrors);
      response!.errors = null as any;
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const client = new ApolloClient({
    uri: `${API_BASE_URL}/graphql`,
    cache,
    link: errorLink.concat(httpLink)
  });

  return <ApolloProvider client={client}><GenericErrorBoundary error={errors[0]?.message}>{props.children}</GenericErrorBoundary></ApolloProvider>;
};
