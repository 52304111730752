import { EventEmitter } from 'events';

import OpusRecorder from 'opus-recorder';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import wavEncoderPath from 'file-loader!opus-recorder/dist/waveWorker.min.js';

interface InitializedRecordingSetup {
  audioContext: AudioContext;
  mediaStream: MediaStream;
  currentInputDevice: MediaDeviceInfo;

  mic: MediaStreamAudioSourceNode;
  analyzer: AnalyserNode;
  recorder: OpusRecorder;
}

export type RecorderState = 'recording' | 'not-recording' | 'not-initialized';
export type RecordingCompleteCallback = (recording: Blob) => void;

export enum ERecorderEvent {
  RECORDING_STARTED = "recording-started",
  RECORDING_STOPPED = "recording-stopped",
  INITIAlIZED = "initialized",
  STATE_CHANGED = "state-changed",
  CLOSED = "closed",
  RECORDING_COMPLETE = "recording-complete"
}

class Recorder extends EventEmitter {
  setup: InitializedRecordingSetup | null = null;
  private state: RecorderState = 'not-initialized';

  static sampleRate = 48000;
  static channelCount = 1;

  async initialize(deviceId?: string): Promise<void> {
    console.log(`Recorder initializing with device id ${JSON.stringify(deviceId)}`)
    if (this.setup !== null) {
      console.log("Recorder already initialized")
      return Promise.resolve();
    }

    console.log("Supported constraints:")
    console.log(navigator.mediaDevices.getSupportedConstraints())

    const advancedConstraints: MediaTrackConstraintSet = {
      autoGainControl: false,
      echoCancellation: false,
      noiseSuppression: false,
      sampleRate: Recorder.sampleRate
    }

    const inferredInputDevice = await this.inferDevice(deviceId)

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: inferredInputDevice?.deviceId,
        advanced: [advancedConstraints]
      }
    });

    await this.onGetUserMediaSuccess(stream, inferredInputDevice);
    this.emit(ERecorderEvent.INITIAlIZED);
  }

  public getState(): RecorderState {
    return this.state;
  }

  public getInputDevices(): Promise<Array<MediaDeviceInfo>> {
    return navigator.mediaDevices.enumerateDevices().then(arr => arr.filter(d => d.kind === "audioinput"))
  }

  private async inferDevice(deviceId?: string): Promise<MediaDeviceInfo | undefined> {
    const devices = await this.getInputDevices();
    return deviceId !== "" ? devices.find(d => d.deviceId === deviceId) : undefined
  }

  private async onGetUserMediaSuccess(stream: MediaStream, passedInputDevice?: MediaDeviceInfo) {
    const AudioContext: typeof window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    const audioContext = new AudioContext();

    const mic = audioContext.createMediaStreamSource(stream);

    const rec = new OpusRecorder({
      sourceNode: mic,
      encoderPath: wavEncoderPath,
      numberOfChannels: Recorder.channelCount,
      mediaTrackConstraints: {
        autoGainControl: false,
        echoCancellation: false,
        noiseSuppression: false,
      },
      wavBitDepth: 32
    });

    // on complete
    rec.ondataavailable = typedArray => {
      const dataBlob = new Blob( [typedArray], { type: 'audio/wav' } );
      this.emit(ERecorderEvent.RECORDING_COMPLETE, dataBlob)
    };

    // for visualization
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 2048;
    mic.connect(analyzer);

    const currentInputDevice = passedInputDevice ? passedInputDevice : (await this.getInputDevices())[0]!;

    this.setup = {
      audioContext,
      mediaStream: stream,
      mic,
      analyzer,
      currentInputDevice,
      recorder: rec
    };
  }

  public async startRecording() {
    // console.log("RecordingService.startRecording")
    if (!this.setup) {
      await this.initialize();
    }
    if (!this.setup) { return; }

    await this.setup.recorder.start();
    this.state = 'recording';
    this.emit(ERecorderEvent.STATE_CHANGED, this.state)
    this.emit(ERecorderEvent.RECORDING_STARTED)
  }

  public stopRecording() {
    // console.log("RecordingService.stopRecording")
    const timer = setTimeout(async () => {
      if (this.setup === null) { return; }
      if (this.state !== 'recording') { return; }

      await this.setup.recorder.stop();

      this.state = 'not-recording';
      this.emit(ERecorderEvent.STATE_CHANGED, this.state)

      clearTimeout(timer);
      this.emit(ERecorderEvent.RECORDING_STOPPED)
    }, 500);
  }

  public async close() {console.log("RecordingService.close")
    if (!this.setup) { return; }

    await this.setup.audioContext.close();

    this.setup = null;
    this.state = "not-initialized";
    this.emit(ERecorderEvent.STATE_CHANGED, this.state)
    this.emit(ERecorderEvent.CLOSED)
  }
}

export default Recorder;
