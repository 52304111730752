import React from 'react';
import Icon, {EIconName} from "../../atoms/icon";
import {useTheme} from "../../../theme";
import classNames from "classnames";
import {css} from "emotion";

export interface IRerecordOptionProps {
  icon: EIconName;
  title: string;
  description: string;
  onSelect(): void;
}

const RerecordOption: React.FC<IRerecordOptionProps> = props => {
  const { colors: themeColors } = useTheme();

  return (
    <div className={classNames("pointer ba b--transparent br3 pa3", css({
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: themeColors.tileAlt
      }
    }))} onClick={props.onSelect}>
      <div><Icon name={props.icon} className={"v-mid"} /><span className={"vocalid-list-title dib v-mid ml3"}>{props.title}</span></div>
      <p className={"ml4 vocalid-body"}>{props.description}</p>
    </div>
  );
};

export default RerecordOption;
