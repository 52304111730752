import React from 'react';
import KeyboardPanelProxy from "../../molecules/keyboard-panel/context";
import BG from "./bg.png"
import classNames from "classnames";
import {css} from "emotion";
import Checkbox from "../../atoms/checkbox";
import SetupTile from "../../molecules/setup-tile";

export interface ISetupRequirementProps {
  checked: boolean;
  onCheck(checked: boolean): void;
  className?: string;
}

const SetupRequirement: React.FC<ISetupRequirementProps> = props => {
  return (
    <li className={classNames("pointer mv3 pv2", props.className)} onClick={e => {
      props.onCheck(!props.checked);
      e.preventDefault();
    }}>
      <Checkbox checked={props.checked} onChange={({ target }) => props.onCheck((target as HTMLInputElement).checked)} className={"v-mid mr3"} /><span className={"v-mid vocalid-list-title"}>{props.children}</span>
    </li>
  );
};

export interface ICheckSetupPageProps {
  onBack(): void;
  onNext(): void;
}

const CheckSetupPage: React.FC<ICheckSetupPageProps> = (props) => {
  const [requirementsMet, setRequirementsMet] = React.useState({
    quietRoom: false,
    headsetMic: false,
    chrome: false,
    laptopOrDesktopComputer: false
  })

  const allRequirementsMet = Object.keys(requirementsMet).reduce((acc, curr) => acc && requirementsMet[curr as keyof typeof requirementsMet], true)

  const requirementProps = (key: keyof typeof requirementsMet): ISetupRequirementProps => ({
    checked: requirementsMet[key],
    onCheck: c => setRequirementsMet(o => ({ ...o, [key]: c }))
  })

  return (
    <div className={"pa4"}>
      <SetupTile imageUrl={BG}>
        <div className={"flex flex-column vocalid-primary-text"}>
          <h2 className={"vocalid-h2 white tl"}>
            Let’s check your setup before continuing
          </h2>
          <div>
            <p className={"vocalid-list-title mb4"}>Before we start your recording session, please review this checklist to make sure you’re ready:</p>
            <ul className={css({
              listStyleType: 'none',
              margin: 0,
              padding: 0,
              '& > li': {
                marginBottom: 10
              }
            })}>
              <SetupRequirement {...requirementProps("quietRoom")}>I'm in a completely quiet room.</SetupRequirement>
              <SetupRequirement {...requirementProps("headsetMic")}>I’m using a high quality microphone to record.</SetupRequirement>
              <SetupRequirement {...requirementProps("chrome")}>I'm using a Google Chrome web browser.</SetupRequirement>
              <SetupRequirement {...requirementProps("laptopOrDesktopComputer")}>I'm using a laptop or desktop computer.</SetupRequirement>
            </ul>
          </div>
        </div>
      </SetupTile>
      <KeyboardPanelProxy currentRecording={null} onBack={props.onBack} onNext={allRequirementsMet ? props.onNext : undefined} />
    </div>
  );
};

export default CheckSetupPage;
