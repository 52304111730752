import React from 'react';
import SetupTile from "../../molecules/setup-tile";
import BG from "./bg.png";
import KeyboardPanelProxy from "../../molecules/keyboard-panel/context";
import RoundedBanner from "../../molecules/rounded-banner";
import RerecordOption from "./shared";
import {EIconName} from "../../atoms/icon";
import { useRecordingTimeout } from './recording-timeout';
import { CircularProgress } from '@material-ui/core';

export interface IMicVolumeCheckTileProps {
  state: 'start' | 'processing' | 'succeeded' | {
    failed: true;
    reason: string;
    recording: Blob;
  };
  onProcessSample(recording: Blob): Promise<void>;
  onResetState(): void;
  onBack(): void;
  onNext(): void;
}

const MicVolumeCheckTile: React.FC<IMicVolumeCheckTileProps> = props => {
  const onNext = () => {
    props.onNext()
    props.onResetState()
  }

  const recordingTimeout = useRecordingTimeout();

  return (
    <>
      <SetupTile imageUrl={BG} className={"relative"}>
        {recordingTimeout.overlay}
        <div className={"flex flex-column flex-grow-1 vocalid-primary-text"}>
          <h2 className={"vocalid-h2 white tl"}>
            Microphone volume check.
          </h2>
          { props.state === 'processing' ?
            <div className={"vocalid-list-title mb4 flex-grow-1 flex justify-center items-center"}>
              <CircularProgress size={50} color={"inherit"} />
            </div>
            : props.state === 'start' ? (
            <div>
              <p className={"vocalid-list-title mb4"}>
                Now, let's check your microphone levels.
              </p>
              <p className={"vocalid-list-title mb4"}>
                Click <span>RECORD</span> and make an "Ahh" noise for 3 seconds.
              </p>
            </div>
          ) : props.state === 'succeeded' ? (
            <div>
              <RoundedBanner>
                Your microphone levels passed!
              </RoundedBanner>
              <div className={"mt3"}>
                <p>
                  Click <span>NEXT</span> to continue.
                </p>
              </div>
            </div>
          ) : (
            <div>
              <RoundedBanner>We detected a problem with your mic levels: {props.state.reason}</RoundedBanner>
              <div className={"mt4"}>
                <div className={"mb3"}>
                  <RerecordOption
                    icon={EIconName.Undo}
                    title={"I need to record my 'Ahh' again."}
                    description={"Please make sure that your headset microphone is selected and adjusted properly."}
                    onSelect={() => props.onResetState()}
                  />
                </div>
                <div className={"mb3"}>
                  <RerecordOption
                    icon={EIconName.Approve}
                    title={"Sounds clear with no background noise to me!"}
                    description={"Continue to the next audio check."}
                    onSelect={() => onNext()}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </SetupTile>
      <KeyboardPanelProxy {...recordingTimeout.keyboardProxyProps} onRecorded={props.state === 'start' ? props.onProcessSample : undefined} currentRecording={props.state !== 'start' && props.state !== 'processing' && props.state !== 'succeeded' ? props.state.recording : null} onBack={props.onBack} onNext={props.state === 'succeeded' ? onNext : undefined} />
    </>
  );
};

export default MicVolumeCheckTile;
