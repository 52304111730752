import React from 'react';
import { CurrentSessionContextProvider } from './current-session-context';
import { AppContextProvider } from './app-context';

const AppStateProviders: React.FC = props => {
  return (
    <AppContextProvider>
      <CurrentSessionContextProvider>
        {props.children}
      </CurrentSessionContextProvider>
    </AppContextProvider>
  );
};

export default AppStateProviders;
