import React from 'react';
import KeyboardPanelProxy from '../../molecules/keyboard-panel/context';
import classNames from 'classnames';
import { css } from 'emotion';
import Sentence, { SentenceUploadStatus } from './sentence';

export interface IStoryPageProps {
  data: {
    story: {
      name: string;
      author: string | null;
    };
    sentences: Array<{
      id: string;
      sentence: string;
      uploadStatus: SentenceUploadStatus;
      readingNotes: string | null;
    }>;
  };
  currentSentenceId: string;
  onPrevious(): void;
  onSentenceRecorded(sentenceId: string, recording: Blob): void;
  onSentenceReported(sentenceId: string, reason: string): void;
  onSkipSentence(): void;
  onSkipStory(): void;
}

const StoryPage: React.FC<IStoryPageProps> = (props) => {
  const currentIndex = props.data.sentences.findIndex((s) => s.id === props.currentSentenceId);
  const currentSentence = currentIndex > -1 ? props.data.sentences[currentIndex] : undefined;
  const [currentRecording, setCurrentRecording] = React.useState<Blob | null>(null);
  const [currentlyRecording, setCurrentlyRecording] = React.useState(false);

  return (
    <div className={'h-100 w-100 pt4'}>
      <div className={'h-100 w-100 flex justify-center items-end'}>
        <div
          className={classNames(
            'bg-vocalid-primary-text flex-grow-1 mh5-m mh6-l',
            css({
              position: 'relative',
              borderRadius: '6px 6px 0px 0px',
              height: '100%',
              overflowY: 'hidden',
              boxShadow: '0 4px 30px -5px rgba(0, 0, 0, 0.2)',
              ':after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background:
                  'linear-gradient(to bottom,rgba(229, 233, 235, 0) 50%,rgba(229, 233, 235, 0.6) 100%), linear-gradient(to bottom,rgba(0,0,0,0) 50%,rgba(0, 0, 0, 0.2) 100%), linear-gradient(to bottom,rgba(0,0,0,0) 85%,rgba(0, 0, 0, 0.1) 100%)',
                pointerEvents: 'none'
              }
            })
          )}
        >
          <div>
            <div
              className={css({
                paddingTop: 50
              })}
            >
              {props.data.sentences.map((sentence, i) =>
                currentIndex === 0 || i >= currentIndex - 1 ? (
                  <Sentence
                    key={i}
                    read={i < currentIndex}
                    uploadStatus={sentence.uploadStatus}
                    active={i === currentIndex}
                    currentlyRecording={currentlyRecording}
                    readingNotes={sentence.readingNotes}
                    onSentenceReported={reason => props.onSentenceReported(sentence.id, reason)}
                    disableReadingNotes={
                      !props.data.sentences.reduce(
                        (acc, curr) => acc || curr.readingNotes != null,
                        false
                      )
                    }
                  >
                    {sentence.sentence}
                  </Sentence>
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
      <KeyboardPanelProxy
        story={{
          ...props.data.story,
          progress:
            props.data.sentences.filter(curr => curr.uploadStatus === SentenceUploadStatus.UploadSucceeded).length /
            props.data.sentences.length
        }}
        onSkip={props.onSkipStory}
        onRecorded={currentSentence?.uploadStatus !== SentenceUploadStatus.Reported ? (recording) => setCurrentRecording(recording) : undefined}
        onBack={
          currentIndex > 0
            ? () => {
                setCurrentRecording(null);
                props.onPrevious();
              }
            : undefined
        }
        onNext={
          currentRecording
            ? () => {
                if (currentRecording) {
                  props.onSentenceRecorded(
                    props.data.sentences[currentIndex]!.id,
                    currentRecording
                  );
                  setCurrentRecording(null);
                }
              }
            : (
              currentSentence?.uploadStatus === SentenceUploadStatus.UploadSucceeded || currentSentence?.uploadStatus === SentenceUploadStatus.Reported
              ? props.onSkipSentence
              : undefined
            )
        }
        currentRecording={currentRecording}
        onRecording={setCurrentlyRecording}
      />
    </div>
  );
};

export default StoryPage;
