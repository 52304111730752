import React, { EventHandler, MouseEvent } from 'react';
import Icon, { EIconName, IIconProps } from './index';

export interface HoverIconProps {
  unHovered: {
    name: EIconName;
    color?: string;
    className?: string;
  };
  hovered: {
    name: EIconName;
    color?: string;
    className?: string;
  };

  active?: boolean;

  size?: number;
  children?: undefined;

  onClick?: EventHandler<MouseEvent<SVGElement>>;
  className?: string;
}

const HoverIcon: React.FC<HoverIconProps> = props => {
  const [isHovering, setIsHovering] = React.useState(false);

  return <span onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} className={props.className}>
    <Icon {...props.unHovered} {...(isHovering || props.active ? props.hovered : {})} size={props.size} onClick={props.onClick} />
  </span>
};

export default HoverIcon;
