import React from 'react';
import StoryPage, { IStoryPageProps } from '../../components/pages/story';
import { CircularProgress } from '@material-ui/core';
import { useCurrentSession } from '../shared/context/app-state/current-session-context';
import GenericErrorBoundary from '../../components/molecules/generic-error-boundary';
import { useSnackbar } from 'notistack';
import { printError } from 'graphql';
import LogRocket from 'logrocket';
import md5 from 'blueimp-md5';
import { SentenceUploadStatus } from '../../components/pages/story/sentence';
import { useAppContext, useAssertedCurrentAssignment } from '../shared/context/app-state/app-context';
import {
  useCreateContentLineTalentReportMutation,
  useCreateRecordingSessionContentLineItemMutation,
  useUpdateRecordingSessionContentLineItemMutation,
  useContentLinesQuery
} from '../../graphql/generated';

export interface IStoryPageContainerProps {
  onComplete(): void;
}

const StoryPageContainer: React.FC<IStoryPageContainerProps> = props => {
  const assignment = useAssertedCurrentAssignment();
  const appContext = useAppContext();

  const currentContentId = assignment?.contentId;
  const content = useContentLinesQuery({ variables: { assignmentId: assignment.id, contentId: currentContentId! }, skip: !currentContentId || !assignment.id, fetchPolicy: 'network-only' }).data?.content;

  const [uploadingSentenceIds, setUploadingSentenceIds] = React.useState<string[]>([]);
  const [uploadedSentenceIds, setUploadedSentenceIds] = React.useState<string[]>([]);
  const [failedUploadSentenceIds, setFailedUploadSentenceIds] = React.useState<string[]>([]);
  const [reportedSentenceIds, setReportedSentenceIds] = React.useState<string[]>([]);

  const sentences = content?.lines.filter(line => line.error === null).map(line => ({
    id: line.id,
    sentence: line.text,
    readingNotes: line.talentRecordingRequestTake?.clip ? `Clip "${line.talentRecordingRequestTake.clip.title ?? "Unnamed Clip"}" of Project "${line.talentRecordingRequestTake.clip.project?.title ?? "Deleted Project"}", Account "${line.talentRecordingRequestTake.clip.project?.account?.name}"` : line.directorsNotes,
    recordingData: line.recording,
    uploadStatus: Boolean(line.recording?.audioUrl) || uploadedSentenceIds.includes(line.id) ?
      SentenceUploadStatus.UploadSucceeded :
      uploadingSentenceIds.includes(line.id) ?
        SentenceUploadStatus.Uploading :
        failedUploadSentenceIds.includes(line.id) ?
          SentenceUploadStatus.UploadFailed :
          line.talentReports.find(r => r.reportedByTalent.id === appContext.user!.talentId) || reportedSentenceIds.includes(line.id) ?
            SentenceUploadStatus.Reported :
            SentenceUploadStatus.NotUploaded
  })) ?? [];

  const reportSentence = useSentenceReporting(appContext.user?.talentId ?? "");
  const uploadSentence = useSentenceRecordingUpload();
  const { currentSentenceId, goToNextSentenceOrComplete, goToPreviousSentence } = useSentenceProgression(sentences, props.onComplete);

  const [errors, setErrors] = React.useState<Array<{ message: string; }>>([]);
  const { enqueueSnackbar } = useSnackbar();

  if (!assignment || !content || !currentSentenceId) return <span className={"vocalid-primary-text"}><CircularProgress color={"inherit"} /></span>

  const story: IStoryPageProps["data"]["story"] = {
    name: content.title || "Unnamed Story",
    author: content.author
  }

  return (
    <GenericErrorBoundary error={errors.length >= 3 ? { message: "3 or more subsequent recording uploads have failed", payload: JSON.stringify(errors, null, 2) } : null}>
      <StoryPage
        data={{
          story,
          sentences
        }}
        onSentenceRecorded={async (sentenceId, recording) => {
          console.log("Sentence recorded");
          let individualAttempts = 0;

          const recordingData = sentences.find(s => s.id === sentenceId)!.recordingData;

          const attemptUpload = (): Promise<void> => {
            if (!uploadingSentenceIds.includes(sentenceId)) setUploadingSentenceIds(ids => [...ids, sentenceId]);

            return uploadSentence(sentenceId, recording, recordingData)
              .then(() => {
                setErrors([]);
                setUploadedSentenceIds(ids => [...ids, sentenceId]);
              })
              .catch(e => {
                individualAttempts++;
                console.warn("RECORDING UPLOAD ERROR");
                console.error(e);
                LogRocket.captureException(e);

                if (individualAttempts === 1) {
                  console.log("Retrying...");
                  // retry if failed once
                  return attemptUpload();
                }

                enqueueSnackbar('An error occurred during uploading.', {
                  variant: 'error',
                });

                setErrors(o => [...o, { message: e.message }]);
                setFailedUploadSentenceIds(ids => [...ids, sentenceId]);
              })
              .finally(() => {
                setUploadingSentenceIds(ids => ids.filter(id => id !== sentenceId));
              })
          }

          attemptUpload();

          goToNextSentenceOrComplete();
        }}
        onSentenceReported={async (sentenceId, reason) => {
          await reportSentence(sentenceId, reason);
          setReportedSentenceIds(o => [...o, sentenceId]);
          goToNextSentenceOrComplete();
        }}
        onSkipSentence={goToNextSentenceOrComplete}
        onPrevious={goToPreviousSentence}
        currentSentenceId={currentSentenceId}
        onSkipStory={props.onComplete}
      />
    </GenericErrorBoundary>
  );
};

export default StoryPageContainer;



const useSentenceProgression = (sentences: IStoryPageProps["data"]["sentences"], onComplete: () => void) => {
  const getFirstSentenceWithoutRecording = () => sentences.find(s => s.uploadStatus === SentenceUploadStatus.NotUploaded)?.id ?? sentences[0]?.id ?? null;

  const [currentSentenceId, setCurrentSentenceId] = React.useState<string | null>(getFirstSentenceWithoutRecording());

  React.useEffect(() => {
    setCurrentSentenceId(getFirstSentenceWithoutRecording());
  }, [sentences.length]);

  return {
    currentSentenceId,
    goToPreviousSentence: () => {
      const previousSentenceId = sentences[sentences.findIndex(s => s.id === currentSentenceId) - 1]?.id;
      if (previousSentenceId) {
        setCurrentSentenceId(previousSentenceId);
      }
    },
    goToNextSentenceOrComplete: () => {
      const currentSentenceIndex = sentences.findIndex(s => s.id === currentSentenceId);
      const remainingUnrecordedSentenceIds = sentences.reduce<string[]>((acc, sentence, i) => i > currentSentenceIndex && sentence.uploadStatus === SentenceUploadStatus.NotUploaded ? [...acc, sentence.id] : acc, []);
      if (remainingUnrecordedSentenceIds.length === 0) {
        onComplete();
      } else {
        const nextSentenceId = remainingUnrecordedSentenceIds[0]!;
        setCurrentSentenceId(nextSentenceId);
      }
    }
  }
};

const useSentenceReporting = (talentId: string) => {
  const [createContentLineTalentReport] = useCreateContentLineTalentReportMutation();

  return async (sentenceId: string, reason: string) => {
    await createContentLineTalentReport({
      variables: {
        input: {
          talentId,
          contentLineId: sentenceId,
          reportReason: reason
        }
      }
    })
  }
};

const useSentenceRecordingUpload = () => {
  const sessionId = useCurrentSession()?.id;

  const [createLineRecordingMutation] = useCreateRecordingSessionContentLineItemMutation();
  const [updateLineRecordingMutation] = useUpdateRecordingSessionContentLineItemMutation();

  // upload
  return async (sentenceId: string, recording: Blob, existingRecordingContentLine: { id: string; uploadUrl: string | null; } | null) => {
    let recordingContentLine = existingRecordingContentLine;

    const isRecordingContentLineValid = () => recordingContentLine?.id != null && recordingContentLine?.uploadUrl != null

    // create recordingContentLine to upload to if it doesn't exist
    if (!isRecordingContentLineValid()) {
      const recordingLineData = await createLineRecordingMutation({ variables: {
        contentLineId: sentenceId,
        sessionId: sessionId!
      }});

      console.log("Line recording created on the backend");
      if (!recordingLineData.data) {
        console.error("Data missing in createRecordingSessionContentLineItem mutation response");
        console.warn("GraphQL Errors:")
        recordingLineData.errors?.forEach(err => {
          console.error(printError(err));
        });
        throw new Error("Data missing in createRecordingSessionContentLineItem mutation response")
      }

      const itemData = recordingLineData.data.createRecordingSessionContentLineItem
      recordingContentLine = {  id: itemData.id, uploadUrl: itemData.uploadUrl  };
    }

    // if it still is not valid, abort
    if (!isRecordingContentLineValid()) {
      throw new Error(`recordingContentLine.id or recordingContentLine.uploadUrl are null: ${JSON.stringify(recordingContentLine)}`);
    }

    const assertedRecordingContentLine = {
      ...recordingContentLine!,
      uploadUrl: recordingContentLine!.uploadUrl!
    }

    // upload recording
    const md5hash = md5(await recording.text())
    console.log(`Generated hash: ${md5hash}`)

    await fetch(assertedRecordingContentLine.uploadUrl, {
      method: 'put',
      body: recording
    });

    console.log('Upload successful');
    const parsedUploadUrl = new URL(assertedRecordingContentLine.uploadUrl);
    const uploadedUrl = parsedUploadUrl.origin + parsedUploadUrl.pathname;

    // validate uploaded file
    const uploadedFile = await fetch(uploadedUrl);
    const uploadedFileMd5Hash = md5(await uploadedFile.text());
    console.log(`Uploaded file hash: ${uploadedFileMd5Hash}`)
    if (md5hash !== uploadedFileMd5Hash) {
      throw new Error(`Uploaded file hash (${uploadedFileMd5Hash}) does not match local hash (${md5hash})`);
    }

    // update line recording with uploaded url
    const updateResult = await updateLineRecordingMutation({
      variables: {
        audioUrl: uploadedUrl,
        recordingId: assertedRecordingContentLine.id
      }
    });

    if (updateResult.errors && updateResult.errors.length !== 0) {
      console.error("Errors in updateLineRecordingMutation mutation response");
      console.warn("GraphQL Errors:")
      updateResult.errors?.forEach(err => {
        console.error(printError(err));
      });
      throw new Error("Errors in updateLineRecordingMutation mutation response")
    }
  }
}
