import React from 'react';
import KeyboardPanelProxy from '../../molecules/keyboard-panel/context';
import Story from './story';
import { PrimaryButton } from '../../atoms/button';
import classNames from 'classnames';
import { css } from 'emotion';
import Icon, { EIconName } from '../../atoms/icon';

export interface IStorySelectionPageProps {
  storyOptions: Array<{
    id: string;
    title: string;
    author: string;
    image: string | null;
    recordedLines: number;
    totalLines: number;
  }>;
  onSelectedStory(id: string): void;
}

const StorySelectionPage: React.FC<IStorySelectionPageProps> = (props) => {
  const storiesPerPage = 3;
  const [storyPage, setStoryPage] = React.useState(0);

  return (
    <div className={"pa4"}>
      { props.storyOptions.length !== 0 ?
        <>
          <h5 className={"vocalid-h2 vocalid-primary-text tc mt3 mb2"}>Which story would you like to read?</h5>
          <div className={"mt4 relative"}>
            {storyPage*storiesPerPage != 0 && <div className={classNames("absolute top-0 bottom-0 flex items-center pl2 pr2 pointer", css({
              right: '100%'
            }))} onClick={() => setStoryPage(p => p - 1)}><Icon name={EIconName.ArrowLeft} size={30} /></div>}
            <div className={"flex justify-center items-center"}>
              {props.storyOptions.slice(storyPage*storiesPerPage, (storyPage+1)*storiesPerPage).map(storyOption => (
                <Story key={storyOption.id}
                       title={storyOption.title}
                       author={storyOption.author}
                       image={storyOption.image}
                       recordedLines={storyOption.recordedLines}
                       totalLines={storyOption.totalLines}
                       onSelect={() => props.onSelectedStory(storyOption.id)} />
              ))}
            </div>
            {props.storyOptions.length > (storyPage+1)*storiesPerPage && <div className={classNames("absolute top-0 bottom-0 flex items-center pl2 pr2 pointer", css({
              left: '100%'
            }))} onClick={() => setStoryPage(p => p + 1)}><Icon name={EIconName.ArrowRight} size={30} /></div>}
          </div>
        </> :
        <>
          <h5 className={"vocalid-h2 vocalid-primary-text tc mt3 mb2"}>There are no more stories to read in this assignment.</h5>
          <div className={"tc mt4"}><PrimaryButton onClick={() => {
            window.location.href = process.env.REACT_APP_TALENT_URL as string;
          }}>Back to<br/>Talent Dashboard</PrimaryButton></div>
        </>
      }
      <KeyboardPanelProxy currentRecording={null} />
    </div>
  );
};

export default StorySelectionPage;
