import React from "react";
import RecordingPlaybackHelper, {ERecordingPlaybackHelperEvent} from "../../playback";

export default function usePlaybackHelper() {
  const [playing, setPlaying] = React.useState(false);
  const [progress, setProgress] = React.useState<number | null>(null);
  const playbackHelperRef = React.useRef(new RecordingPlaybackHelper());

  React.useEffect(() => {
    const ph = playbackHelperRef.current;

    const playingListener = () => {
      setPlaying(true);
    };
    ph.addListener(ERecordingPlaybackHelperEvent.PLAYBACK_STARTED, playingListener);

    const stoppedListener = () => {
      setPlaying(false);
      setProgress(null);
    };
    ph.addListener(ERecordingPlaybackHelperEvent.PLAYBACK_STOPPED, stoppedListener);

    const progressListener = (newProgress: number) => {
      setProgress(newProgress);
    };
    ph.addListener(ERecordingPlaybackHelperEvent.PROGRESS, progressListener);

    return () => {
      ph.removeListener(ERecordingPlaybackHelperEvent.PLAYBACK_STARTED, playingListener);
      ph.removeListener(ERecordingPlaybackHelperEvent.PLAYBACK_STOPPED, stoppedListener);
      ph.removeListener(ERecordingPlaybackHelperEvent.PROGRESS, progressListener);
    }
  }, []);

  return {
    startPlayback(audioData: Blob) {
      playbackHelperRef.current.startPlayback(audioData)
      // setPlaying(true);
    },
    stopPlayback() {
      playbackHelperRef.current.stopPlayback()
      // setPlaying(false);
    },
    close() {

    },
    getPlaybackSetup() {
      return playbackHelperRef.current.setup;
    },
    playing,
    progress
  }
}
