import React from 'react';
import Icon, {EIconName} from "../../atoms/icon";
import {css} from "emotion";
import classNames from "classnames";
import {useTheme} from "../../../theme";

interface ISharedKeyboardButtonProps {
  active: boolean;
  onClick?(): void;
}

export interface IKeyboardButtonProps extends ISharedKeyboardButtonProps {
  icon: EIconName;
  iconSize?: number;
  iconColor?: string;
  iconWrapperClassName?: string;
  label: string;
  keyCodeTrigger?: number;
  className?: string;
}

const KeyboardButton: React.FC<IKeyboardButtonProps> = props => {
  const { colors: themeColors } = useTheme();

  const handleUserKeyPress = React.useCallback(event => {
    const { keyCode } = event;

    if (keyCode === props.keyCodeTrigger && props.active) {
       props.onClick?.()
    }
  }, [props.active, props.onClick]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <button onClick={props.onClick} className={classNames(css({
      margin: '5px 6.5px',
      display: 'inline-block',
      width: 70,
      background: '#243C46',
      boxShadow: 'none',
      color: '#194B64',
      height: 50,
      border: 'none',
      borderRadius: 5,
      cursor: 'not-allowed',
      userSelect: 'none',
      pointerEvents: 'none',
      outline: 'none',
      verticalAlign: 'top',
      '& > span': {
        width: '100%',
        display: 'block',
        textAlign: 'center',
        lineHeight: '12px',
        boxSizing: 'border-box'
      }
    }), props.active && css({
      background: '#416D7D',
      color: themeColors.primaryText,
      cursor: 'pointer',
      pointerEvents: 'all'
    }), props.className)}>
      <span className={classNames(props.iconWrapperClassName, css({
        padding: '0 10px',
        visibility: props.active ? 'visible' : 'hidden'
      }))}><span className={"flex items-center justify-center h-100"}><Icon name={props.icon} active={false} color={props.iconColor ?? themeColors.primaryText} hoverColor={props.iconColor ?? themeColors.primaryText} activeColor={props.iconColor ?? themeColors.primaryText} size={props.iconSize ?? 30} className={css({
      })} /></span></span>
      <span className={css({
        visibility: props.active ? 'visible' : 'hidden',
        padding: '0 0 10px',
        fontSize: 12,
        fontWeight: 'bold'
      })}>{ props.label }</span>
    </button>
  );
};

export const KeyboardButtonSpacer = () => (
  <div className={css({
    display: 'inline-block',
    width: 70,
    height: 50,
    margin: '5px 6.5px',
  })}/>
)

export interface IRecordingKeyboardButtonProps extends ISharedKeyboardButtonProps {
  isRecording: boolean;
  className?: string;
}

export const RecordingKeyboardButton: React.FC<IRecordingKeyboardButtonProps> = ({ isRecording, ...sharedProps }) => {
  return (
    <KeyboardButton icon={EIconName.Recording} iconSize={18} iconWrapperClassName={css({
      paddingTop: 7,
      paddingBottom: 3,
      height: 30,
      verticalAlign: 'top'
    })} iconColor={"#C7372E"} label={isRecording ? 'STOP' : 'REC'} keyCodeTrigger={37} {...sharedProps} />
  );
};

export interface IPlayKeyboardButtonProps extends ISharedKeyboardButtonProps {
  isPlaying: boolean;
  className?: string;
}

export const PlayKeyboardButton: React.FC<IPlayKeyboardButtonProps> = ({ isPlaying, ...sharedProps }) => {
  return (
    <KeyboardButton icon={!isPlaying ? EIconName.Play : EIconName.Stop} iconSize={18} iconWrapperClassName={css({
      paddingTop: 7,
      paddingBottom: 3,
      height: 30,
      verticalAlign: 'top'
    })} label={isPlaying ? 'STOP' : 'PLAY'} keyCodeTrigger={39} {...sharedProps} />
  );
};

export interface IBackKeyboardButtonProps extends ISharedKeyboardButtonProps {
  className?: string;
}

export const BackKeyboardButton: React.FC<IBackKeyboardButtonProps> = ({ ...sharedProps }) => {
  return (
    <KeyboardButton icon={EIconName.FilledArrowUp} iconSize={30} label={"BACK"} keyCodeTrigger={38} {...sharedProps} />
  );
};

export interface INextKeyboardButtonProps extends ISharedKeyboardButtonProps {
  className?: string;
}

export const NextKeyboardButton: React.FC<INextKeyboardButtonProps> = ({ ...sharedProps }) => {
  return (
    <KeyboardButton icon={EIconName.FilledArrowDown} iconSize={30} label={'NEXT'} keyCodeTrigger={40} {...sharedProps} />
  );
};
