import React from 'react';
import { useTheme } from '../../../theme';
import Tooltip from '../../atoms/tooltip';
import { css } from 'emotion';
import classNames from 'classnames';
import Icon, { EIconName } from '../../atoms/icon';
import { CircularProgress } from '@material-ui/core';
import SsmlContentView from './ssml';
import { PrimaryButton } from '../../atoms/button';
import HoverIcon from '../../atoms/icon/hover';
import Input from '../../atoms/input';

export enum SentenceUploadStatus {
  NotUploaded = 'not-uploaded',
  Uploading = 'uploading',
  UploadSucceeded = 'upload-succeeded',
  UploadFailed = 'upload-failed',
  Reported = 'reported'
}

export interface ISentenceProps {
  active?: boolean;
  read?: boolean;
  uploadStatus: SentenceUploadStatus;
  readingNotes: string | null;
  disableReadingNotes: boolean;
  currentlyRecording: boolean;
  children: string;
  onSentenceReported(reason: string): void;
}

const Sentence: React.FC<ISentenceProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const [reportReason, setReportReason] = React.useState("");

  const stateIndicator = <span className={'flex-shrink-0 mr3'}>{
    props.uploadStatus === SentenceUploadStatus.UploadSucceeded ?
      <Icon
        name={
          EIconName.Checkmark
        }
        size={!props.active ? 14 : 18}
        className={css({
          position: 'relative',
          top: 4,
        })}
      /> :
      props.uploadStatus === SentenceUploadStatus.Uploading ?
        <CircularProgress size={!props.active ? 18 : 22} color={"inherit"} /> :
        props.uploadStatus === SentenceUploadStatus.UploadFailed ?
          <Tooltip
            tooltipContent={'Upload Failed'}
          >
            <Icon
              name={
                EIconName.X
              }
              size={!props.active ? 14 : 24}
              className={css({
                position: 'relative',
                top: 4,
              })}
            />
          </Tooltip> :
          <Icon
            name={
              props.currentlyRecording
                ? EIconName.RecordIndicatorActive
                : EIconName.RecordIndicatorInactive
            }
            size={14}
            className={css({
              position: 'relative',
              top: 10,
              visibility: props.active ? 'visible' : 'hidden'
            })}
          />
  }</span>;

  return (
    <div>
      <div
        className={classNames(
          css({
            display: 'block',

            paddingLeft: 50,
            paddingRight: 80,

            marginBottom: 50,
            transition: 'margin .25s'
          }),
          !props.active &&
          css({
            color: themeColors.tertiaryText,
            marginBottom: 10
          }),
          props.read &&
          css({
            color: themeColors.secondaryText
          })
        )}
      >
        <div className={'relative'}>
          <div
            className={classNames(
              'v-top inline-flex justify-end',
              !props.disableReadingNotes
                ? css({
                  width: '20%',
                  maxWidth: 200
                })
                : css({
                  width: '5%'
                })
            )}
          >
            <div className={'vocalid-body flex-grow-1 pr3'}>
              {props.readingNotes && (
                <div className={'mb2'}>
                  {props.active && (
                    <div className={'b'}>
                      Reading Notes
                      <br />
                      <br />
                    </div>
                  )}
                  {props.read && props.readingNotes.length > 50
                    ? props.readingNotes.substring(0, 50) + '...'
                    : props.readingNotes}
                </div>
              )}
            </div>
            {stateIndicator}
          </div>
          <div
            className={classNames(
              'v-top dib',
              !props.disableReadingNotes
                ? 'w-80'
                : css({
                  width: '95%'
                })
            )}
          >
            <Tooltip disabled={props.active || props.uploadStatus !== SentenceUploadStatus.Reported} hideArrow placement={"bottom-start"} containerClassName={"w-100"} tooltipContent={
              <div className={"flex flex-column items-center"}>
                <div className={"tc"}>This sentence has been reported to be containing errors and should be skipped.</div>
              </div>
            }>
              <div
                className={classNames(
                  css({
                    fontFamily: '"Roboto", sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: 30,
                    lineHeight: '35px',
                    transformOrigin: '0% 0%',
                    transition: 'transform .25s'
                  }),
                  !props.active &&
                  css({
                    color: themeColors.tertiaryText,
                    transform: 'scale(0.65)'
                  }),
                  props.read &&
                  css({
                    color: themeColors.secondaryText
                  })
                )}
              >
                <span style={{
                  textDecoration: props.uploadStatus === SentenceUploadStatus.Reported ? "line-through" : undefined
                }}>
                  <SsmlContentView ssml={props.children} />
                  { props.active && <Tooltip
                    interactive
                    tooltipContent={<div className={"flex flex-column items-center"}>
                      {
                        props.uploadStatus !== SentenceUploadStatus.Reported ? <>
                          <div className={"tc mb2 mh3"}>Does this sentence<br/>contain an error?</div>
                          <Input white value={reportReason} placeholder={"Typo"} onChange={setReportReason} className={"w-100 mb2"} />
                          <PrimaryButton disabled={reportReason === ""} size={"small"} onClick={async () => {
                            await props.onSentenceReported(reportReason);
                            setReportReason("");
                          }}>Report</PrimaryButton>
                        </> : <>
                          <div className={"tc mh3"}>This sentence is reported to be containing errors and should be skipped.</div>
                        </>
                      }
                    </div>}
                  >
                    <HoverIcon unHovered={{ name: EIconName.ReportInactive }} hovered={{ name: EIconName.ReportActive }} active={props.uploadStatus === SentenceUploadStatus.Reported} className={"ml2 v-mid"} />
                  </Tooltip> }
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sentence;
