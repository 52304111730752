import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import Tile from '../tile';

export interface FullscreenMessageProps {
  heading: string;
}

const FullscreenMessage: React.FC<FullscreenMessageProps> = props => {
  return (
    <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
      paddingTop: '15%'
    }))}>
      <Tile heading={<div className={'vocalid-h2 mr2'}>{props.heading}</div>} contentClassName={css({
        width: 500
      })}>
        {props.children}
      </Tile>
    </div>
  );
};

export default FullscreenMessage;
