import React from 'react';
import classNames from "classnames";
import {css} from "emotion";
import defaultImage from "./default.png";
import { useTheme } from '../../../../theme';

export interface IStoryProps {
  title: string;
  author: string;
  image: string | null;
  recordedLines: number;
  totalLines: number;
  onSelect(): void;
  className?: string;
}

const Story: React.FC<IStoryProps> = props => {
  const { colors: themeColors } = useTheme();

  return (
    <div onClick={props.onSelect} className={classNames("mh3 flex flex-column justify-end pointer vocalid-primary-text", css({
      position: 'relative',
      backgroundColor: '#14212A',
      width: 300,
      height: 400,
      transform: 'scale(0.92)',
      transition: 'transform 0.25s',
      '&:hover': {
        // color: themeColors.darkText,
        transform: 'scale(1.0)',
        '.story-select-caption': {
          top: 0
        },
        '.story-select-image': {
          opacity: 1,
          filter: 'none'
        },
        '.story-select-progress-text': {
          color: themeColors.primaryText,
          opacity: 0.8
        },
        '.story-select-progress-bar': {
          backgroundColor: "#1561a4",
          // opacity: 0.1
        }
      },
      overflow: 'none'
    }))}>
      <div className={classNames("story-select-image", css({
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${props.image ?? defaultImage})`,
        backgroundSize: 'cover',
        backgroundOrigin: 'center',
        opacity: 0.2,
        filter: 'grayscale(100%)'
      }))}/>
      <div className={"relative z-0"}>
        <div className={classNames("story-select-caption", css({
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#0F416B',
          opacity: 1,
          zIndex: 2,
          transition: 'top 0.25s'
        }))} />
        <div className={"pl3 pr4"}>
          <div style={{
            position: 'relative',
            zIndex: 3
          }}>
            <h1 className={"vocalid-h2 mb2"}>{props.title}</h1>
            <h2 className={"vocalid-h3 mt1 mb3"}>{props.author}</h2>
          </div>
        </div>
        <div className={classNames("pt3", css({
          position: 'relative',
          zIndex: 3
        }))}>
          <div className={classNames("story-select-progress-text flex-shrink-0 mt0 mb3 pl3 pr4 vocalid-tertiary-text")}>{props.recordedLines}/{props.totalLines} Completed ({Math.min(100, Math.ceil((props.recordedLines/props.totalLines)*100))}%)</div>
          <div className={css({
            width: '100%',
            height: 14
          })}>
            <div className={classNames("story-select-progress-bar h-100", css({
              width: `${Math.min(100, Math.ceil((props.recordedLines/props.totalLines)*100))}%`,
              transition: 'width 10ms',
              backgroundColor: "#416D7D"
            }))} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
