import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';
import { Link, LinkProps } from 'react-router-dom';

export interface ISharedButtonProps {
  onClick?(): void;
  className?: string;
  style?: React.CSSProperties;
}

interface IButtonBaseProps extends ISharedButtonProps {
  type?: 'outline' | 'fill' | 'text';
  size?: 'small' | 'medium';
  notUppercase?: boolean;
  disabled?: boolean;
  color?: string;
  textColor?: string;
}

const ButtonBase: React.FC<IButtonBaseProps> = (props) => {
  const { colors: themeColors } = useTheme();

  const btnColor = props.disabled
    ? themeColors.iconInactive
    : props.color
    ? (themeColors as any)[props.color] ?? props.color
    : themeColors.blue;
  const textColor = props.disabled
    ? themeColors.secondaryText
    : props.textColor
    ? (themeColors as any)[props.textColor] ?? props.textColor
    : props.type === 'outline'
    ? btnColor
    : props.type === 'text'
    ? btnColor
    : 'white';

  const baseBtnClass = css({
    borderWidth: 2,
    borderStyle: 'solid',
    padding: '10px 30px',
    borderRadius: 4,
    textTransform: !props.notUppercase ? 'uppercase' : undefined,
    cursor: 'pointer',
    fontSize: 18,
    lineHeight: '21px',
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500
  });

  // type

  const fillClass = css({
    borderColor: btnColor,
    backgroundColor: btnColor,
    color: textColor
  });

  const outlineClass = css({
    backgroundColor: 'transparent',
    borderColor: btnColor,
    color: textColor
  });

  const textClass = css({
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: textColor
  });

  // size

  const smallClass = css({
    padding: '5px 15px',
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal !important' as any,
    fontSize: '14px !important',
    lineHeight: '16px !important',
    borderWidth: 1
  });

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        baseBtnClass,
        props.type === 'outline' ? outlineClass : props.type === 'text' ? textClass : fillClass,
        props.size === 'small' && smallClass,
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </button>
  );
};


// button implementations

export interface IPrimaryButtonProps extends ISharedButtonProps {
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export const PrimaryButton: React.FC<IPrimaryButtonProps> = props => {
  const { colors: themeColors } = useTheme();
  const bgColor = !props.disabled ? themeColors.primaryButton : themeColors.iconInactive;
  const textColor = !props.disabled ? themeColors.primaryButtonText : themeColors.secondaryText;

  const size = props.size ?? "medium";

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        css({
          borderWidth: size !== 'small' ? 2 : 1,
          padding: size !== 'small' ? '10px 30px' : '8px 24px',
          fontSize: size !== 'small' ? 18 : 14,
          lineHeight: size !== 'small' ? '21px' : '16px',
          fontWeight: size !== 'small' ? 500 : 'normal',

          borderStyle: 'solid',
          borderRadius: 4,
          cursor: 'pointer',
          textTransform: 'uppercase',
          fontFamily: '"Roboto", sans-serif',

          borderColor: bgColor,
          backgroundColor: bgColor,
          color: textColor
        }),
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </button>
  );
};

export interface IOutlineButtonProps extends ISharedButtonProps {
  size?: 'small' | 'medium';
  dark?: boolean;
}

export const OutlineButton: React.FC<IOutlineButtonProps> = props => {
  const { colors: themeColors } = useTheme();
  const color = !props.dark ? themeColors.primaryText : themeColors.darkText;

  const size = props.size ?? "small";

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        css({
          borderWidth: size !== 'small' ? 2 : 1,
          padding: size !== 'small' ? '10px 30px' : '8px 24px',
          fontSize: size !== 'small' ? 18 : 14,
          lineHeight: size !== 'small' ? '21px' : '16px',
          fontWeight: size !== 'small' ? 500 : 'normal',

          borderStyle: 'solid',
          borderRadius: 4,
          cursor: 'pointer',
          textTransform: 'uppercase',
          fontFamily: '"Roboto", sans-serif',

          borderColor: color,
          backgroundColor: 'transparent',
          color: color
        }),
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </button>
  );
};

export interface ITextButtonProps extends ISharedButtonProps {
  dark?: boolean;
  notUppercase?: boolean;
}

export const TextButton: React.FC<ITextButtonProps> = props => {
  const { colors: themeColors } = useTheme();
  const color = !props.dark ? themeColors.link : themeColors.darkText;

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        css({
          borderWidth: 2,
          borderStyle: 'solid',
          padding: '5px 15px',
          borderRadius: 4,
          cursor: 'pointer',
          textTransform: !props.notUppercase ? 'uppercase' : undefined,
          fontSize: 14,
          lineHeight: '16px',
          fontFamily: '"Roboto", sans-serif',
          fontWeight: 'normal',

          borderColor: 'transparent',
          backgroundColor: 'transparent',
          color: color
        }),
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </button>
  );
};
