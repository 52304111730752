import React from "react";
import { CircularProgress } from "@material-ui/core";

export const Loader: React.FC = props => {
  return (
    <span className={"vocalid-primary-text"}>
      <CircularProgress color={"inherit"}/>
    </span>
  );
};

const FullPageLoader: React.FC = props => {
  return (
    <div className={"flex h-75 justify-center items-center"}>
      <Loader />
    </div>
  );
};

export default FullPageLoader;
