import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import {
  BackKeyboardButton,
  KeyboardButtonSpacer,
  NextKeyboardButton, PlayKeyboardButton,
  RecordingKeyboardButton
} from "./keyboard-buttons";
import {KeyboardPanelProxyContext} from "./context";
import useRecorder from "../../../services/recording/bindings/react";
import usePlaybackHelper from "../../../services/recording/bindings/react/playback";
import {AudioVisualizerView} from "../../atoms/audio-visualizer";
import Tooltip from '../../atoms/tooltip';
import { useTheme } from '../../../theme';

export interface IBaseKeyboardPanelProps {
  story?: {
    name: string;
    author: string | null;
    progress: number;
  };
  onBack?(): void;
  onNext?(): void;
  onRecorded?(recording: Blob): void;
  onSkip?(): void;
  currentRecording: Blob | null;
  onRecording?(currentlyRecording: boolean): void;
  playDisabled?: boolean;
  panelRef?: React.Ref<KeyboardPanelRef>;
}

export interface IKeyboardPanelProps {
  className?: string;
}

export interface KeyboardPanelRef {
  startRecording(): void;
  stopRecording(): void;
}

const KeyboardPanel: React.FC<IKeyboardPanelProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const { data: passedProps } = React.useContext(KeyboardPanelProxyContext);

  const rec = useRecorder(recording => {
    console.log(recording)
    passedProps.onRecorded?.(recording)
  });

  React.useEffect(() => {
    passedProps.onRecording?.(rec.state === 'recording');
  }, [rec.state])

  const playback = usePlaybackHelper();

  React.useImperativeHandle(passedProps.panelRef, () => ({
    startRecording: rec.startRecording,
    stopRecording: rec.stopRecording
  }), [rec]);

  return (
    <div
      className={classNames(
        'bg-vocalid-header',
        css({
          height: 163
        }),
        props.className
      )}
    >
      <div className={"flex justify-between h-100 ph4 items-center"}>
        <div className={classNames("h-100 flex-shrink-0 flex flex-column", css({
          width: '18%'
        }))}>
          {passedProps.story &&
          <>
            <h3 className={"vocalid-list-title vocalid-primary-text mt3 mb1"}>{passedProps.story.name}</h3>
            <h3 className={"flex-shrink-0 vocalid-list-title vocalid-secondary-text mv1"}>{passedProps.story.author}</h3>
            <div className={"flex-grow-1"}/>
            <div className={"flex-shrink-0 flex justify-between items-center mt2 mb3 pr4"}>
              <div className={"flex-shrink-0 vocalid-tertiary-text mv2"}>{Math.ceil(passedProps.story.progress*100)}% Complete</div>
              {passedProps.onSkip && <Tooltip tooltipContent={<>You can come back to this story at any point,<br/>your progress is saved automatically.</>} containerClassName={"v-mid"}><button className={"vocalid-link bg-transparent bn pointer pa0 ma0"} onClick={passedProps.onSkip}>Skip</button></Tooltip>}
            </div>
          </>}
        </div>
        <div className={classNames("h-100 flex-grow-1 flex flex-column", css({
          backgroundColor: '#264757'
        }))}>
          <div className={"relative w-100 flex-grow-1"}>
            <AudioVisualizerView enabled={rec.state === "recording" || playback.playing} getSetup={() => {
              if (rec.state === "recording") {
                const setup = rec.getRecordingSetup()!;

                return {
                  analyzerNode: setup.analyzer
                }
              } else {
                const setup = playback.getPlaybackSetup()!;

                return {
                  bufferSize: setup.bufferSize,
                  analyzerNode: setup.nodes.analyzer
                }
              }
            }} progress={playback.progress ?? undefined} />
          </div>
          <div className={css({
            width: '100%',
            height: 10,
            flexShrink: 0
          })}>
            <div className={classNames("h-100", css({
              width: `${Math.ceil((passedProps.story?.progress ?? 0)*100)}%`,
              transition: 'width 10ms',
              backgroundColor: themeColors.iconActive
            }))} />
          </div>
        </div>
        <div className={classNames("flex-shrink-0 flex justify-center", css({
          width: 285
        }))}>
          <div>
            <div>
              <KeyboardButtonSpacer />
              <BackKeyboardButton active={Boolean(passedProps.onBack)} onClick={passedProps.onBack}/>
              <KeyboardButtonSpacer />
            </div>
            <div>
              <RecordingKeyboardButton active={Boolean(passedProps.onRecorded)} isRecording={rec.state === "recording"} onClick={() => {
                if (rec.state !== 'recording') {
                  rec.startRecording()
                } else {
                  rec.stopRecording()
                }
              }}/>
              <NextKeyboardButton active={Boolean(passedProps.onNext)} onClick={passedProps.onNext}/>
              <PlayKeyboardButton active={passedProps.currentRecording !== null} isPlaying={playback.playing} onClick={() => {
                const audioData = passedProps.currentRecording ?? null;

                if (playback.playing) {
                  playback.stopPlayback()
                } else if (audioData) {
                  playback.startPlayback(audioData)
                }
              }}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyboardPanel;
