import React from 'react';
import BaseSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from '../../../theme';
import classNames from 'classnames';
import Icon, { EIconName } from '../../atoms/icon';
import { css } from 'emotion';
import { Props as CreatableProps } from 'react-select/src/Creatable';

export interface ISelectProps {
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  value: string | string[];
  children: Array<ISelectOption>;

  color?: string;
  disabled?: boolean;
  isMulti?: boolean;
  styleOverrides?: Partial<{
    multiValue: React.CSSProperties;
    multiValueLabel: React.CSSProperties;
    multiValueRemove: React.CSSProperties;
  }>;
  multiValueRemoveIconColor?: string;
  creatable?: boolean;

  onChange?(newValue: string | string[]): void;
  onBlur?(): void;
  onCreateValue?(newValue: string): void;

  className?: string;
}

interface ISelectOption {
  value: string;
  label: string;
}

const Select: React.FC<ISelectProps> = (props) => {
  const SelectComponent: React.ComponentType<CreatableProps<ISelectOption>> = React.useMemo(
    () => (props.creatable ? CreatableSelect : BaseSelect),
    [props.creatable]
  );

  const { colors: themeColors } = useTheme();
  const color = props.disabled
    ? themeColors.iconInactive
    : props.color
    ? (themeColors as any)[props.color] ?? props.color
    : themeColors.secondaryText;

  const menuColor = themeColors.iconInactive;

  const values = Array.isArray(props.value) ? props.value : [props.value];

  return (
    <div className={classNames('dib', props.className)}>
      {props.label && (
        <label
          className={classNames(
            'dib vocalid-h3 vocalid-secondary-text mb1',
            css({ margin: '0px 2px 5px 2px' })
          )}
        >
          {props.label}
        </label>
      )}
      <SelectComponent
        value={values
          .map((value) => props.children.find((o) => o.value === value))
          .filter((e) => e!)
          .map((e) => e!)}
        placeholder={props.placeholder ?? 'Select...'}
        isMulti={props.isMulti}
        menuPosition={'fixed'}
        menuPortalTarget={document.getElementById("selects")}
        components={{
          DropdownIndicator: () => (
            <Icon
              name={EIconName.DropdownArrowDown}
              color={color}
              hoverColor={color}
              size={25}
              className={classNames(
                'pl1 pr2',
                css({
                  '&:hover': {
                    fill: 'transparent !important'
                  }
                })
              )}
            />
          ),
          IndicatorSeparator: () => null,
          MultiValueRemove: ({ innerProps: { onClick } }) => (
            <Icon
              name={EIconName.X}
              color={props.multiValueRemoveIconColor ?? 'primaryText'}
              size={16}
              onClick={onClick}
              className={css({
                paddingTop: 1
              })}
            />
          )
        }}
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: 'transparent',
            color,
            borderRadius: 4,
            borderColor: color,
            appearance: 'none',
            lineHeight: 1.15,
            // padding: '8px 40px 8px 8px',
            borderStyle: 'solid',
            borderWidth: 1,
            minHeight: 40,
            minWidth: 140
          }),
          input: (base) => ({
            ...base,
            color,
            paddingTop: 4,
            paddingBottom: 4
          }),
          placeholder: (base) => ({
            ...base,
            color
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: menuColor,
            color: themeColors.primaryText,
            borderRadius: 4
          }),
          option: (base) => ({
            ...base,
            color: themeColors.primaryText,
            backgroundColor: menuColor,
            '&:hover': {
              color: themeColors.primaryText,
              backgroundColor: themeColors.tile
            }
          }),
          noOptionsMessage: (base) => ({
            ...base,
            color: themeColors.primaryText
          }),
          singleValue: (base) => ({
            ...base,
            color
          }),
          multiValue: (base) => ({
            ...base,
            ...props.styleOverrides?.multiValue
          }),
          multiValueLabel: (base) => ({
            ...base,
            ...props.styleOverrides?.multiValueLabel
          }),
          multiValueRemove: (base) => ({
            ...base,
            ...props.styleOverrides?.multiValueLabel
          })
        }}
        // className={props.className}
        isClearable={false}
        onChange={(v, { action, removedValue }) => {
          switch (action) {
            case 'remove-value':
            case 'pop-value':
              break;
            // case 'clear':
            //   v = []
            //   break;
          }

          if (props.isMulti) {
            const vA = v as ISelectOption[] | null;
            props.onChange?.(vA !== null ? vA.map((vO) => vO.value) : []);
          } else {
            const vO = v as ISelectOption | null;
            props.onChange?.(vO !== null ? vO.value : '');
          }
        }}
        options={props.children}
        onCreateOption={
          props.onCreateValue &&
          ((inputValue) => {
            props.onCreateValue?.(inputValue);
          })
        }
        createOptionPosition={'last'}
      />
    </div>
  );
};

export default Select;
