import React from 'react';
import { IBaseKeyboardPanelProps, KeyboardPanelRef } from '../../molecules/keyboard-panel';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';

export const useRecordingTimeout = (): { overlay: JSX.Element, keyboardProxyProps: Pick<IBaseKeyboardPanelProps, "onRecording" | "panelRef"> } => {
  const { colors: themeColors } = useTheme();

  const [isRecording, setIsRecording] = React.useState(false);
  const [secondsLeft, setSecondsLeft] = React.useState(3);
  const recordingTimerRef = React.useRef<any | null>(null);
  const panelRef = React.useRef<KeyboardPanelRef | null>(null);

  return {
    overlay: <>
      { isRecording &&
        <div className={classNames("absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center tc", css({
          pointerEvents: 'none',
          backgroundColor: 'rgba(0, 0, 0,0.25)'
        }))}>
          <div className={classNames("vocalid-primary-text vocalid-h1 flex justify-center items-center", css({
            width: 100,
            height: 100,
            backgroundColor: "#416D7D",
            fontSize: 50,
            borderRadius: 50
          }))}>
            <span>{secondsLeft}</span>
          </div>
        </div>
      }
    </>,
    keyboardProxyProps: {
      onRecording(isRecording: boolean) {
        setIsRecording(isRecording);
        if (isRecording) {
          const doTimeout = (sec: number) => {
            setSecondsLeft(sec);
            recordingTimerRef.current = setTimeout(() => {
              if (sec > 0) {
                const newSecLeft = sec - 1;
                doTimeout(newSecLeft);
              } else {
                // stop recording
                panelRef.current?.stopRecording();
              }
            }, 1000);
          }

          doTimeout(3);
        } else {
          if (recordingTimerRef.current) {
            clearTimeout(recordingTimerRef.current);
          }
        }
      },
      panelRef
    }
  }
}
