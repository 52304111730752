import React from 'react';
import IntroTile from "./intro";
import BackgroundNoiseCheckTile, {IBackgroundNoiseCheckTileProps} from "./background-noise-check";
import MicVolumeCheckTile, {IMicVolumeCheckTileProps} from "./mic-volume-check";

export interface ICalibrationPageProps {
  backgroundNoiseState: IBackgroundNoiseCheckTileProps["state"];
  onBackgroundNoiseSample(recording: Blob): Promise<void>;
  onBackgroundNoiseResetState(): void;

  micVolumeState: IMicVolumeCheckTileProps["state"];
  onMicVolumeSample(recording: Blob): Promise<void>;
  onMicVolumeResetState(): void;

  onBack(): void;
  onNext(): void;
}

const CalibrationPage: React.FC<ICalibrationPageProps> = props => {
  const [stepIndex, setStepIndex] = React.useState(0);

  const goToPreviousStep = () => stepIndex > 0 && setStepIndex(o => o - 1);
  const goToNextStep = () => stepIndex < steps.length - 1 ? setStepIndex(o => o + 1) : props.onNext();

  const steps = [
    <IntroTile onBack={props.onBack} onNext={goToNextStep} />,
    <BackgroundNoiseCheckTile
      state={props.backgroundNoiseState}
      onProcessSample={props.onBackgroundNoiseSample}
      onResetState={props.onBackgroundNoiseResetState}
      onBack={goToPreviousStep} onNext={goToNextStep} />,
    <MicVolumeCheckTile
      state={props.micVolumeState}
      onProcessSample={props.onMicVolumeSample}
      onResetState={props.onMicVolumeResetState}
      onBack={goToPreviousStep} onNext={goToNextStep} />
  ];

  return (
    <div className={"pa4"}>
      <div>
        {steps[stepIndex]}
      </div>
    </div>
  );
};

export default CalibrationPage;
