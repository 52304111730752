import React from 'react';
import KeyboardPanelProxy from "../../molecules/keyboard-panel/context";
import BG from "./bg.png"
import classNames from "classnames";
import {css} from "emotion";
import {useTheme} from "../../../theme";
import Avatar from "../../atoms/avatar";
import SetupTile from "../../molecules/setup-tile";
import Select from '../../molecules/select';

export interface IConfirmUserPageProps {
  data: {
    user: {
      firstName: string;
      lastName: string;
    }
    requestedSpecificAssignment: boolean;
    assignment: {
      name: string;
    } | null;
    hasContent: boolean;
  };
  onNext(): void;
  onChangeAccountOrAssignment(): void;
}

const ConfirmUserPage: React.FC<IConfirmUserPageProps> = (props) => {
  const { colors: themeColors } = useTheme();

  return (
    <div className={"pa4"}>
      <SetupTile imageUrl={BG}>
        <div className={"flex flex-column items-center tc vocalid-primary-text"}>
          <h2 className={"vocalid-h2 white"}>
            {props.data.assignment ? `Before we start, please confirm your account and current assignment.` : !props.data.requestedSpecificAssignment ? `There are no pending assignments for the current account.` : `The requested assignment could not be found.` }
          </h2>
          <Avatar bgColor={themeColors.red} size={80} className={"mt4 mb3"}>
            {`${props.data.user.firstName.substring(0, 1)}${props.data.user.lastName.substring(0, 2)}`}
          </Avatar>
          <p className={"vocalid-list-title"}>{props.data.user.firstName} {props.data.user.lastName}</p>
          {props.data.assignment ? <>
            <p className={"vocalid-list-title"}>Assignment: {props.data.assignment.name}</p>
            <div className={"mt4"}>
              {props.data.hasContent ? <p className={"vocalid-body"}>
                Click <span className={"vocalid-list-title"}>NEXT</span> at bottom right (or use your down arrow key)
              </p> : <p className={"vocalid-body"}>
                This assignment has no remaining content. <a
                href={process.env.REACT_APP_TALENT_URL as string}
                className={'db vocalid-link no-underline bn bg-inherit pa0 pointer mb1 mt2 ttu'}
              >
                Return to Talent Dashboard
              </a>
              </p>}
              {/* If this is your account and current assignment, */}
{/*
              <button className={"vocalid-link bg-transparent bn pointer"} onClick={props.onChangeAccountOrAssignment}>Change account or assignment</button>
*/}
            </div>
          </> : <>
            {/*<p className={"vocalid-body"}>
              If this is not your account,
            </p>
            <button className={"vocalid-link bg-transparent bn pointer"} onClick={props.onChangeAccountOrAssignment}>Change account</button>*/}
          </>}
        </div>
      </SetupTile>
      <KeyboardPanelProxy currentRecording={null} onNext={props.data.assignment && props.data.hasContent ? props.onNext : undefined} />
    </div>
  );
};

export default ConfirmUserPage;
