import React, { FormEvent } from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';

export interface InputValidationResultError {
  error: string;
}

export interface InputValidationResultWarning {
  warning: string;
}

export type InputValidationResult =
  | InputValidationResultError
  | InputValidationResultWarning
  | true;

export type InputMask = ReactInputMaskProps["mask"];

export interface IInputProps {
  type?: string;
  value: string;
  onChange?(newValue: string): void;
  onBlur?(): void;
  onFocus?(): void;
  onKeyDown?(key: string): void;
  onKeyPress?(key: string): void;

  placeholder?: string;
  white?: boolean;
  label?: React.ReactNode;
  validation?: (value: string | null) => InputValidationResult;
  validationResult?: InputValidationResult;
  size?: 'small' | 'medium';

  mask?: InputMask;
  maskChar?: string;
  disabled?: boolean;

  className?: string;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (props, ref) => {
    const { colors: themeColors } = useTheme();
    const [validationResult, setValidationResult] = React.useState<InputValidationResult>(
      props.validationResult ?? (props.validation ? props.validation(props.value) : true)
    );

    React.useEffect(() => {
      if (props.validationResult !== undefined) {
        setValidationResult(props.validationResult);
      }
    }, [props.validationResult]);

    React.useEffect(() => {
      if (props.validation) {
        setValidationResult(props.validation(props.value));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, props.validation]);

    const inputProps: Partial<React.HTMLProps<HTMLInputElement>> = {
      placeholder: props.placeholder,
      type: props.type,
      value: props.value ?? '',
      readOnly: !props.onChange,
      onChange: (e: FormEvent<HTMLInputElement>) =>
        props.onChange?.((e.target as HTMLInputElement).value),
      onBlur: props.onBlur,
      onFocus: props.onFocus,
      onKeyDown: e => props.onKeyDown?.(e.key),
      onKeyPress: e => props.onKeyPress?.(e.key),
      className: classNames(
        css({
          minHeight: props.size === 'small' ? 25 : 40,
          backgroundColor: !props.white ? '#376071' : themeColors.primaryText,
          borderColor: !props.white ? themeColors.secondaryText : 'transparent',
          color: !props.white ? themeColors.secondaryText : themeColors.darkText,
          fontSize: 14,
          '&::placeholder': {
            color: 'inherit',
            opacity: 0.6
          },
          opacity: !props.disabled ? 1 : 0.3
        }),
        'db pv2 ph2 w-100 ba'
      ),
      style: {
        borderRadius: 4
      }
    };

    return (
      <div className={classNames('relative db', props.className)}>
        {props.label && (
          <label
            className={classNames(
              'dib vocalid-h3 vocalid-secondary-text mb1',
              css({ margin: '0px 2px 5px 2px' })
            )}
          >
            {props.label}
          </label>
        )}
        {!props.mask ? (
          <input ref={ref} {...inputProps} />
        ) : (
          <ReactInputMask
            inputRef={ref}
            mask={props.mask}
            maskChar={props.maskChar}
            {...inputProps as any}
          />
        )}
        {validationResult !== true && (
          <div className={'mt1'}>
            {validationResult.hasOwnProperty('error') ? (
              <span className={'vocalid-red'}>
                {(validationResult as InputValidationResultError).error}
              </span>
            ) : (
              <span className={'vocalid-warning'}>
                {(validationResult as InputValidationResultWarning).warning}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
