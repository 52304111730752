import React from 'react';
import ConfirmUserPage from "../../components/pages/confirm-user";
import { useAppContext } from '../shared/context/app-state/app-context';

export interface IConfirmUserPageContainerProps {
  onNext(): void;
}

const ConfirmUserPageContainer: React.FC<IConfirmUserPageContainerProps> = props => {
  const user = useAppContext().user!;

  return (
    <ConfirmUserPage
      data={{
        user: {
          firstName: user.firstName,
          lastName: user.lastName
        },
        requestedSpecificAssignment: Boolean(user.requestedAssignmentId),
        assignment: user.assignment ? {
          name: user.assignment.title
        } : null,
        hasContent: Boolean(user.assignment?.contentId)
      }}
      onNext={props.onNext}
      onChangeAccountOrAssignment={() => {}}
    />
  );
};

export default ConfirmUserPageContainer;
