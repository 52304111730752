import React from 'react';
import Tippy from '@tippyjs/react/headless';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';

export interface ITooltipProps {
  visible?: boolean;
  tooltipContent: React.ReactNode;
  tooltipElStyle?: React.CSSProperties;
  arrowStyle?: React.CSSProperties;
  hideArrow?: boolean;
  interactive?: boolean;
  placement?: Placement;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;

  color?: string;
  onClickOutside?(): void;
}

const Tooltip: React.FC<ITooltipProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const color = props.color
    ? (themeColors as any)[props.color] ?? props.color
    : themeColors.popover;

  const arrowPositionCss = (inferredPlacement: Placement): string => {
    return inferredPlacement.startsWith('top')
      ? css({
          bottom: -4
        })
      : inferredPlacement.startsWith('bottom')
      ? css({
          top: -4
        })
      : inferredPlacement.startsWith('left')
      ? css({
          right: -4
        })
      : inferredPlacement.startsWith('right')
      ? css({
          left: -4
        })
      : '';
  };

  return (
    <Tippy
      visible={props.visible}
      onClickOutside={() => props.onClickOutside?.()}
      render={(attrs) => (
        <div
          className={classNames(
            'vocalid-body',
            css({
              maxWidth: 300,
              padding: 10,
              background: color,
              borderRadius: 3,
              color: themeColors.darkText,
              zIndex: 999
            })
          )}
          {...attrs}
          style={props.tooltipElStyle}
        >
          <div className={props.className}>{props.tooltipContent}</div>
          {!props.hideArrow && (
            <div
              className={classNames(
                css({
                  '&, &::before': {
                    position: 'absolute',
                    width: 8,
                    height: 8,
                    zIndex: -1
                  },
                  '&::before': {
                    content: '""',
                    transform: 'rotate(45deg)',
                    background: color
                  }
                }),
                attrs['data-placement'] && arrowPositionCss(attrs['data-placement'])
              )}
              data-popper-arrow=""
              style={props.arrowStyle}
            />
          )}
        </div>
      )}
      interactive={props.interactive}
      placement={props.placement ?? 'bottom'}
      disabled={props.disabled ?? false}
    >
      <div
        className={classNames(
          'dib',
          css({
            position: 'relative'
          }),
          !props.disabled && 'pointer',
          props.containerClassName
        )}
      >
        {props.children}
      </div>
    </Tippy>
  );
};

export default Tooltip;
