import React from 'react';
import AppTemplate from '../../components/templates/app';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import GenericErrorBoundary from '../../components/molecules/generic-error-boundary';
import { useAppContext } from '../shared/context/app-state/app-context';
import FullscreenMessage from '../../components/atoms/fullscreen-message';
import { PrimaryButton } from '../../components/atoms/button';
import { useAuth } from '../../auth';

export const PLACEHOLDER_IMAGE = "https://via.placeholder.com/300"

export interface IAppTemplateContainerProps {}

const AppTemplateContainer: React.FC<IAppTemplateContainerProps> = (props) => {
  const { user, loading, error } = useAppContext()
  const auth0 = useAuth();
  const logout = () => {
    auth0.logout(process.env.REACT_APP_TALENT_URL as string);
  }

  const history = useHistory();

  if (loading) return <span className={"vocalid-primary-text"}><CircularProgress color={"inherit"} /></span>

  if (!user) {
    return <FullscreenMessage heading={"No talent account found"}>
      No talent account found for user <strong>{auth0.user.email ?? auth0.user.name}</strong>.<br/>
      Try logging in with a different account.
      <div className={"flex justify-end mt4"}>
        <PrimaryButton
          onClick={logout}
          size={"small"}
          className={"db"}
        >
          Sign Out
        </PrimaryButton>
      </div>
    </FullscreenMessage>
  }

  const data = {
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.emailAddress,
      imageUrl: PLACEHOLDER_IMAGE
    }
  }

  return (
    <GenericErrorBoundary error={error?.message}>
      <AppTemplate data={data} onSignOut={logout}>
        <GenericErrorBoundary>
          {props.children}
        </GenericErrorBoundary>
      </AppTemplate>
    </GenericErrorBoundary>
  );
};

export default AppTemplateContainer;
