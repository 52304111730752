import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';
import Icon, { EIconName } from '../icon';

export interface ICheckboxProps
  extends React.HTMLProps<HTMLInputElement>,
    React.HTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
  backgroundColor?: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({ indeterminate, className, ...props }) => {
  const { colors: themeColors } = useTheme();

  const backgroundColor = props.backgroundColor
    ? (themeColors as any)[props.backgroundColor] ?? props.backgroundColor
    : themeColors.secondaryText;

  return (
    <label
      className={classNames(
        css({
          display: 'inline-block',
          position: 'relative',
          cursor: 'pointer',
          userSelect: 'none',
          height: 20,
          width: 20
        }),
        className
      )}
    >
      <input
        type={'checkbox'}
        ref={(el) => el && (el.indeterminate = indeterminate ?? false)}
        className={css({
          position: 'absolute',
          opacity: 0,
          cursor: 'pointer',
          height: 0,
          width: 0,
          [`&:checked ~ .checkbox-icon`]: {
            display: 'flex'
          },
          [`&:indeterminate ~ span::before`]: {
            display: 'block'
          }
        })}
        {...(props as any)}
      />
      <span
        className={css({
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor,
          borderRadius: 2,
          zIndex: 0,
          '&::before': {
            display: 'none',
            content: "''",
            position: 'absolute',
            left: 3,
            top: 9,
            right: 3,
            bottom: 9,
            backgroundColor: themeColors.tile
          }
        })}
      />
      <div
        className={
          'dn checkbox-icon absolute top-0 left-0 right-0 bottom-0 justify-center items-center z-1'
        }
      >
        <Icon name={EIconName.Checkmark} color={'tile'} size={12} />
      </div>
    </label>
  );
};

export default Checkbox;
