import React from "react";
import AudioVisualizer, {EAudioVisualizerEvent, IVizProps} from "../../visualizer";
import { useTheme } from '../../../../theme';

export interface RecordingVisualizerInput {
  enabled: boolean;
  getSetup(): RecordingVisualizerInputSetup;
}

export interface RecordingVisualizerInputSetup {
  analyzerNode: AnalyserNode;
}

export default function useAudioVisualizer(input: RecordingVisualizerInput): [(canvasEl: HTMLCanvasElement | null) => void, {
  active: boolean;
} & IVizProps] {
  const visualizerRef = React.useRef(new AudioVisualizer());
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const [active, setActive] = React.useState(false);
  const [vizProps, setVizProps] = React.useState<IVizProps>({
    peakInstantaneousPowerDecibels: 0
  });

  const { colors: themeColors } = useTheme();

  React.useEffect(() => {
    const viz = visualizerRef.current;
    viz.clippingBoundsLineColor = "#416D7D";
    viz.dangerColor = themeColors.red;

    const setActiveListener = () => setActive(true);
    const setInactiveListener = () => setActive(false);

    viz.addListener(EAudioVisualizerEvent.VISUALIZATION_STARTED, setActiveListener);
    viz.addListener(EAudioVisualizerEvent.VISUALIZATION_STOPPED, setInactiveListener);

    const updateVizPropsListener = (v: IVizProps) => {
      setVizProps(v);
    };

    viz.addListener(EAudioVisualizerEvent.DB_PROPS_UPDATED, updateVizPropsListener);

    return () => {
      viz.removeListener(EAudioVisualizerEvent.VISUALIZATION_STARTED, setActiveListener);
      viz.removeListener(EAudioVisualizerEvent.VISUALIZATION_STOPPED, setInactiveListener);
      viz.removeListener(EAudioVisualizerEvent.DB_PROPS_UPDATED, updateVizPropsListener);
    }
  }, []);

  React.useEffect(() => {
    if (input.enabled) {
      const recordingSetup = input.getSetup();
      if (recordingSetup && !visualizerRef.current.isInitialized()) {
        visualizerRef.current.startVisualization({
          analyzerNode: recordingSetup.analyzerNode
        });
      }
    } else {
      visualizerRef.current.stopVisualization()
    }
  }, [input.enabled]);

  return [
    (canvasEl: HTMLCanvasElement | null) => {
      canvasRef.current = canvasEl;
      if (canvasEl) {
        visualizerRef.current.setCanvas(canvasEl);
      }
    }, {
      active,
      ...vizProps
    }
  ];
}
